import React from 'react';
import { render } from 'preact';
import { BaseComponent } from 'project/general';
import { ServiceEditorService } from 'project/services';

export default class AsdSelfServiceControls extends BaseComponent {

    nodeGUID = "";
    serviceListingFormUrl = "";
    serviceAccessFormUrl = "";
    serviceDeletionFormUrl = "";
    userContext = "";

    constructor(...args) {
        super(...args);
    }

    static getNamespace() {
        return 'asd-self-service-controls';
    }

    static getRequiredRefs() {
        return ["placeholder"];
    }

    onInit() {
     
       this.nodeGUID = this.options.nodeGuid;
       this.serviceListingFormUrl = this.options.serviceListingFormUrl;
       this.serviceAccessFormUrl = this.options.serviceAccessFormUrl;
       this.serviceDeletionFormUrl = this.options.serviceDeletionFormUrl;

        //  Load info about the user log in
        this.servicePageData = ServiceEditorService.getAsdSelfServiceUserContext(this.options.nodeGuid).then((userContext) => {

            if (userContext != undefined) {

                this.userContext = userContext;

                //  user signed in
                if (this.userContext.signedIn && this.userContext.selfServiceEnabledForUser) {


                    if (this.userContext.canEdit && this.userContext.listingRequestsEnabled) {

                        if (this.userContext.hasPendingListingRequest) {

                            this.renderPendingListingRequest();

                        }
                        else {

                            this.renderEditingControls();

                        }
                    }

                    else if (!this.userContext.canEdit && this.userContext.accessRequestsEnabled) {

                        if (this.userContext.hasPendingAccessRequest) {

                            this.renderPendingAccessRequest();

                        }
                        else {

                            this.renderRequestAccessPrompt();
                        }

                    }

                }

                //  user not signed in
                else if (!this.userContext.signedIn && this.userContext.listingRequestsEnabled) {

                    this.renderSignInPrompt();

                }
               
            }

        });
    }

    onServiceListingForm = () => {
        window.location.href = this.serviceListingFormUrl;
    }

    onServiceDeletionForm = () => {
        if (confirm("Are you sure you want to delete this service?") == true) {
            window.location.href = this.serviceDeletionFormUrl;
        }
    }

    renderEditingControls(userContext) {

        render(
            <div class="self-service-panel">
                <p><button type="button" class="button button--primary" onClick={this.onServiceListingForm}>Edit</button></p>
                <p><button type="button" class="button button--secondary" onClick={this.onServiceDeletionForm}>Delete</button></p>
            </div>
            , this.refs.placeholder
        );
    }

    renderSignInPrompt(userContext) {
        render(
            <div class="self-service-panel">
                <p>Is this your service? If so, please <a href={this.userContext.signInUrl}>sign in</a> to edit this service.</p>
            </div>
            , this.refs.placeholder
        );
    }

    renderRequestAccessPrompt() {
        render(
            <div class="self-service-panel">
                <p>Is this your service? If so, please <a href={this.serviceAccessFormUrl}>request access</a> to edit this service.</p>
            </div>
            , this.refs.placeholder
        );
    }

    renderPendingAccessRequest() {
        render(
            <div class="self-service-panel">
                <p>You've asked us to let you edit this service, you'll be notified when your request has been processed.</p>
            </div>
            , this.refs.placeholder
        );
    }

    renderPendingListingRequest() {
        render(
            <div class="self-service-panel">
                <p>There's an edit to this service that's waiting to be processed</p>
                <p>If it's you that submitted it you'll be notified when it's been processed.</p>
            </div>
            , this.refs.placeholder
        );
    }

    onCreateLabel(labelText) {
        var label = document.createElement("label");
        label.innerText = labelText;
        this.refs.placeholder.appendChild(label);
    }

}
