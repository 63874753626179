import { dcFactory } from 'dc';
import { BaseComponent } from 'project/general';

export class MicrositeNavComponent extends BaseComponent {
    static getNamespace() {
        return 'microsite-navigation';
    }

    onInit() {
        const block = this.refs.block;
        const inner = this.refs.inner;
        const navWrapper = document.querySelector('.nav-wrapper');
        if (document.body.clientWidth < 1120) {
            navWrapper.appendChild(block)
        }
        else {
            inner.appendChild(block)
        }
        window.addEventListener('resize', function () {
            if (document.body.clientWidth < 1120) {
                navWrapper.appendChild(block)
            }
            else {
                inner.appendChild(block)
            }
        });
        
    }

}

export default MicrositeNavComponent;
