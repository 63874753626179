function MobileNav() {
    this.curItem,
        this.curLevel = 0,
        this.transitionEnd = _getTransitionEndEventName();

    // Set the navigation element
    var defaults = {
        initElem: ".navigation",
        menuTitle: "Menu",
    }

    MobileNav.prototype.getCurrentItem = function () {
        return this.curItem;
    };

    MobileNav.prototype.setMenuTitle = function (title) {
        defaults.menuTitle = title;
        _updateMenuTitle(this);
        return title;
    };


    // Init is an anonymous IIFE
    (function (MobileNav) {
        var initElem = ($(defaults.initElem).length) ? $(defaults.initElem) : false;

        if (initElem) {
            defaults.initElem = initElem;
            _clickHandlers(MobileNav);
            _updateMenuTitle(MobileNav);
        } else {
            console.log(defaults.initElem + " element doesn't exist, menu not initialized.");
        }
    }(this));

    function _getTransitionEndEventName() {
        var i,
            undefined,
            el = document.createElement('div'),
            transitions = {
                'transition': 'transitionend',
                'OTransition': 'otransitionend', // oTransitionEnd in very old Opera
                'MozTransition': 'transitionend',
                'WebkitTransition': 'webkitTransitionEnd'
            };

        for (i in transitions) {
            if (transitions.hasOwnProperty(i) && el.style[i] !== undefined) {
                return transitions[i];
            }
        }
    };

    function _clickHandlers(menu) {
        if ($(window).width() < 1125) {
            defaults.initElem.on('click', '.navigation__item--has-dropdown > a', function (e) {
                e.preventDefault();
                menu.curItem = $(this).parent();
                _updateActiveMenu(menu);

                var subMenu = $(this).parent().find('.navigation__list--main').find('.navigation__list--second');
                var firstLevel = $(this).parent().find('.navigation__item--first-level');
                var subMenuHeight = $(subMenu).height();
                $(firstLevel).css("height", subMenuHeight);
            });

            defaults.initElem.on('click', '.nav-toggle', function () {
                _updateActiveMenu(menu, 'back');
            });
        }
    };


    function _updateActiveMenu(menu, direction) {
        _slideMenu(menu, direction);
        if (direction === "back") {
            menu.curItem.removeClass('nav-dropdown-open nav-dropdown-active');
            menu.curItem = menu.curItem.parent().closest('.navigation__item--main');
            menu.curItem.addClass('nav-dropdown-open nav-dropdown-active');
            _updateMenuTitle(menu);
        } else {
            menu.curItem.addClass('nav-dropdown-open nav-dropdown-active');
            _updateMenuTitle(menu);
        }
    };

    // Update main menu title to be the text of the clicked menu item
    function _updateMenuTitle(menu) {
        var title = defaults.menuTitle;
        if (menu.curLevel > 0) {
            title = menu.curItem.children('a').text();
            defaults.initElem.find('.nav-toggle').addClass('back-visible');
            defaults.initElem.addClass('navigation--upper-level');
        } else {
            defaults.initElem.find('.nav-toggle').removeClass('back-visible');
            defaults.initElem.removeClass('navigation--upper-level');
            $('.nav-toggle').css({
                "transform": "translateX(0%)"
            });
        }
        $('.nav-title').text(title);
    };

    // Slide the main menu based on current menu depth
    function _slideMenu(menu, direction) {
        if ($(window).width() < 1125) {
            if (direction === "back") {
                menu.curLevel = (menu.curLevel > 0) ? menu.curLevel - 1 : 0;
                menu.backLevel = (menu.backLevel > 0) ? menu.backLevel - 2 : 0;
            } else {
                menu.curLevel += 1;
                menu.backLevel = 1;
            }
            defaults.initElem.children('ul').css({
                "transform": "translateX(-" + (menu.curLevel * 100) + "%)"
            });
            $('.utility-block').css({
                "transform": "translateX(-" + (menu.curLevel * 100) + "%)"
            });
            $('.inner-right__search-box').css({
                "transform": "translateX(-" + (menu.curLevel * 100) + "%)"
            });
            $('.nav-toggle').css({
                "transform": "translateX(-" + (menu.backLevel * 100) + "%)"
            });
        }
    };
}



$(window).on("load resize", function (e) {
    var bodyWidth = $('body').outerWidth();

    if (bodyWidth < 1125) {
        var MobileMenu = new MobileNav({
            initElem: ".navigation",
        });

        $('.js-nav-toggle').on('click', function (e) {
            e.preventDefault();
            $('.nav-wrapper').toggleClass('show-menu');
            $('.page-header').toggleClass('menu-active');
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
        });

        $('.js-close-menu').on('click', function (e) {
            e.preventDefault();
            $('.page-header').removeClass('menu-active');
            $('.nav-wrapper').removeClass('show-menu'); 
            document.body.style.position = '';
            document.body.style.top = '';
        });
    }

    else {
        e.preventDefault();
        $('.page-header').removeClass('menu-active');
        $('.nav-wrapper').removeClass('show-menu');
    }

});
