import React from 'react';
import { Fragment } from 'preact';
import { MultiSelectBase } from './multi-select-base.jsx';
import { MultiSelectDropdown } from './multi-select-dropdown.jsx';
import { NasFormFunctions } from '../../classes/nas-form-functions';
export class MultiSelectInput extends MultiSelectBase {

    renderSelectedOptions() {

        var array = [];
        this.nasFormFunctions = new NasFormFunctions();
        var formField = this.props.field;
        if (Array.isArray(formField.value)) {
            formField.value.map((arrayItem) => {
                var option = this.nasFormFunctions.getOptionById(arrayItem.id, formField.property.options);
                if (option != undefined) {
                    array.push(option.displayName);
                }
            })
        }

        var output = array.toString();
        output = output.replaceAll(",", ", ");
        return output;

    }

    render() {
        var fieldName = this.props.field.name + "#" + this.props.field.dataObjectId;
        var options = this.props.treeViewItem.children;
        var inputCss = "form-field__checkbox";
        return (
            <Fragment>

                {
                    (this.props.editMode == true) &&
                    options.map((option) => {

                        var isOptionSelected = this.props.field.isOptionSelected(option);
                        var checkBoxId = this.createCheckboxId(option);

                        return (
                         <Fragment>

                                {
                                    //  If this option has no children, create a single checkbox for the option
                                    (option.children == undefined || option.children.length == 0) &&
                                    <Fragment>
                                        <div class="form-field__checkbox-item form-field--checkbox">
                                                <input type="checkbox"
                                                    id={checkBoxId}
                                                    name={this.props.field.inputElementName + "[]"}
                                                    value={option.id}
                                                    checked={isOptionSelected ? "checked" : ""}
                                                    aria-checked={isOptionSelected ? "True" : "False"}
                                                    onChangeCapture={this.onItemClick}
                                                    aria-label={option.displayName}
                                                    class={inputCss} />
                                            <span class="checkmark"></span>
                                                <label class="form-field__label" for={checkBoxId}>{option.displayName}</label>
      
                                        </div>
                                    </Fragment>
                                }

                                {
                                    //  If this option has children, create a multi-select dropdown for the child options
                                    (option.children != undefined && option.children.length > 0) &&
                                    <Fragment>
                                    {
                                        <MultiSelectDropdown
                                            field={this.props.field}
                                            treeViewItem={option}
                                            userEditKey={this.props.userEditKey}
                                            name={this.props.field.inputElementName}
                                            onValueChanged={this.props.onValueChanged}
                                        />
                                    }
                                    </Fragment>
                                }

                        </Fragment>

                    )
                    })

                }
                {
                    (this.props.editMode == false) &&
                    this.renderSelectedOptions()
                }

            </Fragment>
        );
    }

}
