import React from 'react';

export class EventOptionItemRadio extends React.Component {
    render() {
        const {
            name, title, description, price, enabled, clientId, variantId, quantityClientName
        } = this.props;
        const rootCss = enabled ? 'event-option--enabled' : 'event-option--disabled';
        return (


            <label className={`event-option-radio ${rootCss}`}>
                <div className="event-option__main">
                    <h5 className="event-option__title heading-6">{title}</h5>
                    {description && <div className="event-option__caption">{description}</div>}
                </div>
                <div className="event-option__price" dangerouslySetInnerHTML={{ __html: price.formatted }}></div>
                <div className="event-option__radio">
                    <input className="form-field__radio"
                        id={clientId}
                        name={name}
                        type="radio"
                        required
                        data-parsley-required-message="Required"
                        data-parsley-errors-container="#radio-event-error-container"
                        value={variantId}
                        disabled={!enabled}
                    />
                    <input type="hidden" name={quantityClientName} value="1" />
                    <label htmlFor={clientId}>&nbsp;</label>
                </div>
            </label>


        );
    }
}
