import React from 'react';
import PropTypes from 'prop-types';
import { dcFactory } from 'dc';

export class AccountOrdersAccordion extends React.Component {
    constructor(props) {
        super(props);

        this.accordionRef = React.createRef();
    }

    componentDidMount() {
        dcFactory.init(this.accordionRef.current);
    }

    componentWillUnmount() {
        dcFactory.destroy(this.accordionRef.current);
    }

    render() {
        const {
            id,
            ariaLabelledby,
            childCss,
            isExpanded,
            isShowIcon,
            trigger,
            content,
        } = this.props;

        return (
            <div ref={this.accordionRef}>


                <dl className={`${childCss} accordion`} data-dc-accordion="" data-allow-multiple=""
                    data-allow-toggle="" role="presentation">
                    <dt role="heading" aria-level="3">
                        <button
                            className={`${childCss}__trigger accordion__trigger`}
                            id={ariaLabelledby}
                            type="button"
                            aria-controls={id}
                            aria-expanded={isExpanded}
                        >
                            {trigger}
                            {
                                isShowIcon &&
                                <span className={`${childCss}__icon accordion__icon`}>
                                    <span></span>
                                    <span></span>
                                </span>
                            }
                        </button>
                    </dt>
                    <dd
                        className={`${childCss}__panel accordion__panel`}
                        id={id}
                        aria-labelledby={ariaLabelledby}
                        hidden={!isExpanded}
                        role="region"
                    >
                        {content}
                    </dd>
                </dl>
            </div>
        );
    }
}

AccountOrdersAccordion.defaultProps = {
    childCss: '',
    isExpanded: false,
    isShowIcon: true,
};

AccountOrdersAccordion.propTypes = {
    id: PropTypes.string,
    ariaLabelledby: PropTypes.string,
    isExpanded: PropTypes.bool,
    isShowIcon: PropTypes.bool,
};
