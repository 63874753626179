import React from 'react';
import { Fragment } from 'preact';
import { NasFormFunctions } from '../classes/nas-form-functions';

export class RadioButtonInput extends React.Component {

    #nasFormFunctions = undefined;
    nasFormFunctions = () => {
        if (this.#nasFormFunctions == undefined) {
            this.#nasFormFunctions = new NasFormFunctions();
        }
        return this.#nasFormFunctions;
    }

    onValueChanged = (event) => {
        this.props.field.setValue(event.target.value, this.props.userEditKey);
        this.nasFormFunctions().markOptionSelected(this.props.field.value, this.props.field.property.options, true);
        this.props.onValueChanged(this.props.field);
    }

    render() {
        var options = this.props.treeViewItem.children;
        return (
            <div class="form-control">
                <span class={this.props.cssClass}>

                    {options.map((option) => {

                        var inputId = this.props.field.inputElementId + "#" + option.id;
                        var isOptionSelected = this.props.field.isOptionSelected(option);
                        var checked = isOptionSelected ? "checked" : "";
                        
                        return (
                            <span class="ktc-radio">
                                <input type="radio" id={inputId} name={this.props.field.inputElementName} value={option.id} onChangeCapture={this.onValueChanged} class="form-control form-field__radio" checked={checked} />
                                <label for={inputId}>{option.displayName}</label>
                            </span>
                        )

                    })}

                </span>
            </div>
        );
    }
}
