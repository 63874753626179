import { dcFactory } from 'dc';
import { apiNames, BaseComponent } from 'project/general';


export class SocialButtonsComponent extends BaseComponent {
    static getNamespace() {
        return 'social-sharing';
    }

    onInit() {

        this.refs.facebook.style.display = "none";
        this.refs.twitter.style.display = "none";
        this.refs.linkedin.style.display = "none";
        var socialCookie = localStorage.getItem("third-party_cookies");

        // Only show sharing button if cookie compliance is met
        if (socialCookie == "true") {
            this.refs.facebook.style.display = "block";
            this.refs.twitter.style.display = "block";
            this.refs.linkedin.style.display = "block";
            // Inject Linkedin Scripts
            var linkedInScript = document.createElement("script");
            linkedInScript.setAttribute("src", "https://platform.linkedin.com/in.js");
            linkedInScript.setAttribute("type", "text/javascript");
            linkedInScript.innerHTML = "lang: en_GB";
            var linkedInButton = document.createElement("script");
            linkedInButton.setAttribute("type", "IN/Share");
            linkedInButton.setAttribute("data-url", document.URL);
            this.refs.linkedin.appendChild(linkedInScript);
            this.refs.linkedin.appendChild(linkedInButton);


            // Inject Twitter Scripts
            var twitterScript = document.createElement("script");
            twitterScript.setAttribute("src", "https://platform.twitter.com/widgets.js");
            twitterScript.setAttribute("type", "text/javascript");
            twitterScript.setAttribute("charset", "utf-8");
            var twitterShareButton = document.createElement("a");
            twitterShareButton.href = "https://twitter.com/share?ref_src=twsrc%5Etfw";
            twitterShareButton.classList.add("twitter-share-button")
            twitterShareButton.innerText = "share";
            this.refs.twitter.appendChild(twitterScript);
            this.refs.twitter.appendChild(twitterShareButton);

            //Facebook Scripts
            var facebookDiv = document.createElement("div");
            facebookDiv.id = "fb-root";
            var facebookShareButton = document.createElement("div");
            facebookShareButton.classList.add("fb-share-button")
            facebookShareButton.setAttribute("data-href", document.URL);
            facebookShareButton.setAttribute("data-layout", "button");
            this.refs.facebook.appendChild(facebookDiv);
            this.refs.facebook.appendChild(facebookShareButton);

            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }


    }

}

export default SocialButtonsComponent;
