import { BaseComponent } from 'project/general';

class ScrollDownComponent extends BaseComponent {

    static getNamespace() {
        return 'scroll-down';
    }

    onInit() {
        this.element.addEventListener('click', this.onClick);
    }

    onClick = () => {
        const nextSection = document.querySelectorAll('.generic-section')[1];
        nextSection.scrollIntoView();
    };


}

export default ScrollDownComponent;
