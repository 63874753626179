

import React from 'react';
import { render } from 'preact';
import qs from 'qs';
import { baseApi } from 'project/api';
import { apiNames, BaseComponent } from 'project/general';
import { getApiUrlByName } from 'project/helpers';
import { EventOptionListRoot } from './event-option-list-root';

const ENDPOINT_EVENT_EXPANDED_COBPONENT = getApiUrlByName(apiNames.EVENT_EXPANDED_COBPONENT);

export class EventExpandedStep extends BaseComponent {
    static getNamespace() {
        return 'event-expanded-step';
    }

    constructor(element, options) {
        super(element);
        this.options = { ...this.options, ...options };
    }

    onInit() {
        this.addListener(this.refs.primaryContainer, 'change', this.onPrimaryChanged);
        this.renderSecondary(this.initData.expandedFormComponent);
    }

    renderSecondary(expandedFormComponent, isLoading = false) {
        render(<EventOptionListRoot
            {...expandedFormComponent}
            isLoading={isLoading}/>, this.refs.secondaryContainer);
    }

    onPrimaryChanged = () => {
        this.renderSecondary(null, true);
        const formData = this.options.formInstance.getRequestData();
        const query = EventExpandedStep.getQueryFromFormdata(formData);
        baseApi.get(`${ENDPOINT_EVENT_EXPANDED_COBPONENT}?${query}`).then((result) => {
            if (result.data.success) {
                this.renderSecondary(result.data.data);
            }
        });
    };

    static getQueryFromFormdata(formData) {
        const obj = {};
        obj.OptionIds = formData.OptionIds;
        obj.EventSkuId = formData.EventSkuId;
        obj.AllowedVariantSkuIds = formData.AllowedVariantSkuIds;
        return qs.stringify(obj);
    }
}
