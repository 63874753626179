import { BaseComponent } from 'project/general';
import { gtmService, EcommerceTracking } from 'project/services';
import { trackingTypes, triggers } from './enums';

// send event on click
// data-dc-gtm='{"tracking": ["category", "action ", "label"]}'

// send any data on click
// data-dc-gtm='{"trackingType": "any", "tracking": {"foo": "bar"}}'

// send any data on page load
// data-dc-gtm='{"trackingType": "any", "trigger": "appearance", "tracking": {"foo": "bar"}}'

class GtmComponent extends BaseComponent {
    constructor(element) {
        super(element);
        let datasets = null;
        if (Array.isArray(this.options)) {
            datasets = this.options;
        } else {
            datasets = [this.options];
        }
        this.datasets = GtmComponent.prepareDataSets(datasets);
    }

    static getNamespace() {
        return 'gtm';
    }

    static prepareDataSets(datasets) {
        return datasets.map((trackingData) => {
            return { ...GtmComponent.defaultOptions, ...trackingData };
        });
    }

    static get defaultOptions() {
        return {
            trackingType: trackingTypes.EVENT,
            trigger: triggers.CLICK
        };
    }

    onInit() {
        this.datasets.forEach((trackingData) => {
            switch (trackingData.trigger) {
                case triggers.CLICK:
                    this.addListener(this.element, 'click', () => this.onClick(trackingData));
                    break;
                case triggers.APPEARANCE:
                    this.sendTrackingAccordingType(trackingData);
                    break;
                default:
                    console.error(`Can not handle the the tracking trigger ${trackingData.trigger}`);
            }
        });
    }

    onClick = (trackingData) => {
        this.sendTrackingAccordingType(trackingData);
    };

    sendTrackingAccordingType(trackingData) {
        switch (trackingData.trackingType) {
            case trackingTypes.EVENT:
                gtmService.pushEvent(...trackingData.tracking);
                break;
            case trackingTypes.ANY:
                gtmService.pushData(trackingData.tracking);
                break;
            case trackingTypes.ECOMMERCE_IMPRESSION:
                EcommerceTracking.trackImpression(trackingData.tracking);
                break;
            default:
                console.error(`Can not handle the the tracking type ${trackingData.trackingType}`);
        }
    }
}

export default GtmComponent;
