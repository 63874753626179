import { BaseComponent } from 'project/general';
import { copyToClipboard } from 'project/helpers';

export default class CopyToClipboardComponent extends BaseComponent {
    static getNamespace() {
        return 'copy-to-clipboard';
    }

    onInit() {
        this.addListener(this.element, 'click', this._onClickButton);
        this.addListener(this.element, 'transitionend', this._hideTooltip);
    }

    _onClickButton = () => {
        if (this.options.text) {
            copyToClipboard(this.options.text);

            this._showTooltip();
        }
    };

    _showTooltip = () => {
        this.element.classList.add('is-visible');
    };

    _hideTooltip = () => {
        this.element.classList.remove('is-visible');
    };
}
