import React from 'react';
import { Fragment } from 'preact';
import { WizardStep } from './wizard-step';
import { InputHelper } from '../input-fields/input-helper.js';
export class Wizard extends React.Component {

    state = {
        currentStepIndex: -1,
        userEditKey: Date.now(),
        mapApiKey: this.props.mapApiKey
    };

    onValueChanged = (field) => {
        this.props.onValueChanged(field);
    }

    onPreviousStepClick = () => {
        if (this.state.currentStepIndex > 0) {
            this.props.steps[this.state.currentStepIndex].active = false;
            this.state.currentStepIndex -= 1;
            this.props.steps[this.state.currentStepIndex].active = true;
            this.setState({ currentStepIndex: this.state.currentStepIndex });
        }
        window.scrollTo(0, 0);
    }

    onNextStepClick = () => {
        if (this.state.currentStepIndex < this.props.steps.length - 1) {
            this.props.steps[this.state.currentStepIndex].active = false;
            this.state.currentStepIndex += 1;
            this.props.steps[this.state.currentStepIndex].active = true;
            this.setState({ currentStepIndex: this.state.currentStepIndex });
        }
        window.scrollTo(0, 0);
    }

    setActiveStep = () => {

        if (this.props.steps != undefined && this.props.steps.length > 0) {

            if (this.state.currentStepIndex == -1) {
                for (var i = 0; i < this.props.steps.length; i++) {
                    if (i == 0) {
                        this.props.steps[i].active = true;
                        this.state.currentStepIndex = i;
                    }
                    else {
                        this.props.steps[i].active = false;
                    }
                }
                this.setState({ currentStepIndex: this.state.currentStepIndex });
            }
            else if (this.state.currentStepIndex > -1) {

                if (this.props.steps[this.state.currentStepIndex].active != true) {
                    this.props.steps[this.state.currentStepIndex].active = true;
                }
            }

        }

    }
   
    getValidationFixStep = () => {

        var outputStep = {
            name: "The following issues prevented your listing from being submitted", properties: [], formFields: {}, active: true, stepType: "validation" };

        if (this.props.steps != undefined && this.props.steps.length > 0) {

            for (var stepIndex = 0; stepIndex < this.props.steps.length; stepIndex++) {

                var step = this.props.steps[stepIndex];

                if (Array.isArray(step.formFields)) {

                    var formFieldNames = Object.keys(step.formFields);

                    for (var nameIndex = 0; nameIndex < formFieldNames.length; nameIndex++) {

                        var formFieldName = formFieldNames[nameIndex];
                        var field = step.formFields[formFieldName];

                        if (Array.isArray(field.validationErrors) && field.validationErrors.length > 0) {
                            outputStep.properties.push(field.property);
                            outputStep.formFields[field.property.name]=field;
                        }

                    }
                }

            }

        }

        return outputStep;

    }



    render() {

        var inputCssClass = this.props.inputCssClass;
        var inputHelper = new InputHelper(true, this.state.userEditKey, this.onValueChanged, inputCssClass, this.state.mapApiKey);
        this.setActiveStep();
        var steps = this.props.steps;
        var validationStep = this.getValidationFixStep();
        var inValidationFixMode = (validationStep == undefined || validationStep?.properties.length == 0) ? false : true;
        var stepClass = inValidationFixMode ? "steps hidden" : "steps";
        var showSubmitButton = (this.state.currentStepIndex == steps.length - 1 );
        return (
            <div class="wizard">

                {
                    (validationStep?.properties.length > 0) &&
                        <WizardStep step={validationStep} inputHelper={inputHelper} />
                }

          
                <div class={stepClass}>
                {
                steps.map((step) => {
                        return (
                            <WizardStep step={step} inputHelper={inputHelper} />
                        )
                    })
                }
                </div>

   
                <div class="footer">

                    {
                        (!inValidationFixMode && this.state.currentStepIndex > 0) &&
                        <button type="button" class="button button--primary" onClick={this.onPreviousStepClick}>Previous</button>
                    }

                    {
                        (!inValidationFixMode && this.state.currentStepIndex < steps.length - 1) &&
                        <button type="button" class="button button--primary" onClick={this.onNextStepClick}>Next</button>
                    }
                    
                    {
                        (showSubmitButton) &&
                        <button type="button" class="button button--primary" onClick={this.props.onSubmitClick}>Submit</button>
                    }

                </div>
            </div>
        );
    }

}

