import React from 'react';
import { Quantity } from 'shared/quantity';
import { generateBemCss } from 'project/helpers';

export class EventOptionItemQuantity extends React.Component {
    render() {
        const {
            title, description, price, quantityClientName, enabled, productIdClientName, clientId, variantId, onQuantityChange, quantity, quantityMaxValue
        } = this.props;
        const bemRoot = enabled ? ['event-option--enabled'] : ['event-option--disabled'];
        const rootCss = generateBemCss('event-option', bemRoot);
        return (


            <div className={`event-expanded__item event-option ${rootCss}`}>
                <input type="hidden" name={productIdClientName} value={variantId} />

                <div className="event-option__main">
                    <h5 className="event-option__title heading-6">{title}</h5>
                    {description &&
                        <div className="event-option__caption">{description}</div>
                    }

                </div>
                <div className="event-option__price" dangerouslySetInnerHTML={{
                    __html: price.formatted
                }}>
                </div>
                <div className="event-option__quantity">
                    <Quantity
                        childCss={'event-option__quantity-control'}
                        quantity={quantity}
                        bemList={['event']}
                        name={quantityClientName}
                        min={0}
                        max={quantityMaxValue}
                        disabledInput={false}
                        onChange={(newQuantity) => {
                            onQuantityChange(newQuantity, clientId);
                        }}
                    />

                </div>
            </div>


        );
    }
}
