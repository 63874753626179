import React from 'react';
import { SubformController } from './subform-controller';
//import { FormField } from '../input-fields/form-field.js';
//import { InputHelper } from '../input-fields/input-helper.js';
import { Fragment } from 'preact';
import { Map } from '../map';
import { app } from 'general';
import { resourceLoader } from 'project/resource-loader';
import { baseApi } from 'project/api';
import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';


const GET_COORDINATES = getApiUrlByName(apiNames.GET_COORDINATES);

export class LocationFields extends SubformController {

    state = {
        subformEditMode: true,
        inputHelper: this.props.inputHelper,
        userEditKey: this.props.userEditKey,
        isCollapsed: this.props.dataObject.isCollapsed == undefined ? true : this.props.dataObject.isCollapsed
    }
    loqateApi = null;
    formFields = null;


    initialiseFormFields() {

        var properties = this.props.property.childProperties;
        var dataObject = this.props.dataObject;
        this.formFields = this.props.property.onCreateFormFields(this.props.property.name, properties, dataObject);

    }

    onToggleAccordion = () => {
        if (this.state.isCollapsed) {
            this.state.isCollapsed = false;
        }
        else {
            this.state.isCollapsed = true;
        }
        this.setState({ isCollapsed: this.state.isCollapsed });
    }

    getAccordionTitle = () => {
        var accordionTitle = "";
        if (this.props.dataObject.locationName == undefined || this.props.dataObject.locationName == "") {
            accordionTitle = "Location " + (parseInt(this.props.dataObject.index) + 1);
        }
        else {
            accordionTitle = this.props.dataObject.locationName;
        }
        return accordionTitle;
    }

    initialiseLoqateApi(formFields) {

        this.readyPromise = resourceLoader.load(app.getConfig('loqate').resources)
        .then(() => {
           
            // bind input elements to loqate fields
            var formFields = this.formFields;
            var loqateFields = [
                { field: "BuildingName", element: formFields.houseName.inputElementName, mode: pca.fieldMode.POPULATE },
                { field: "BuildingNumber", element: formFields.houseNumber.inputElementName, mode: pca.fieldMode.POPULATE },
                { field: "Street", element: formFields.street.inputElementName, mode: pca.fieldMode.POPULATE },
                { field: "District", element: formFields.locality.inputElementName, mode: pca.fieldMode.POPULATE },
                { field: "City", element: formFields.town.inputElementName, mode: pca.fieldMode.POPULATE },
                { field: "Province", element: formFields.county.inputElementName, mode: pca.fieldMode.POPULATE },
                { field: "PostalCode", element: formFields.postcode.inputElementName, mode: pca.fieldMode.DEFAULT },
                { field: "CountryIso2", element: formFields.country.inputElementName, mode: pca.fieldMode.POPULATE }
            ];

            //  initialise loqate api
            var options = { key: app.getConfig('loqate').apiKey }
            this.loqateApi = new pca.Address(loqateFields, options);

            //  add event handler to trigger when the address is picked
            this.loqateApi.listen('populate', (address) => {
                this.onAddressSelected(address);
            });

        });
    }

    onAddressSelected(address) {

        var changedFields = [];

        //  when loqate api populates the address fields it doesn't trigger the
        //  onValueChanged event (which would normally update the form fields state)
        var formFields = this.formFields;
        var userEditKey = this.state.userEditKey;
        var loqateFields = this.loqateApi.fields;
        var id = this.props.dataObject.id;
        for (var i = 0; i < loqateFields.length; i++) {
            var loqateField = loqateFields[i];
            var fieldName = loqateField.element;
            var formField = this.getFieldByInputElementName(loqateField.element);
            var value = address[loqateField.field];
            if (formField.value != value) {
                formField.setValue(value, userEditKey);
                if (formField.isDirty()) {
                    changedFields.push(formField);
                }
            }
        }

        //  get the coordinates for the map (async)
        var coords = this.getCoordinates().then((result) => {

            var changedFields = [];
            if (result != undefined && result.latitude != undefined && result.longitude != undefined) {

                var latField = this.formFields.latitude;
                if (result.latitude != latField.value) {
                    this.formFields.latitude.setValue(result.latitude, this.state.userEditKey);
                    changedFields.push(latField);
                }

                var lonField = this.formFields.longitude;
                if (result.longitude != lonField.value) {
                    this.formFields.longitude.setValue(result.longitude, this.state.userEditKey);
                    changedFields.push(lonField);
                }
            }
            if (changedFields.length > 0) {
                this.props.onValueChanged(changedFields);
            }
        });

        if (changedFields.length > 0) {
            this.props.onValueChanged(changedFields);
        }
    }

    getFieldByInputElementName = (inputElementName) => {

        var keys = Object.keys(this.formFields)
        for (const key of keys) {
            var formField = this.formFields[key];
            if (formField.inputElementName == inputElementName) {
                return formField;
            }
        }
        return undefined;

    }

    onMapMarkerMoved = (id, lat, lng) => {

        var latField = this.formFields.latitude;
        var lonField = this.formFields.longitude;

        latField.setValue(lat, this.state.userEditKey);
        lonField.setValue(lng, this.state.userEditKey);


        var array = [];
        array.push(latField);
        array.push(lonField);
        this.props.onValueChanged(array);

    }

    getAddressString = () => {
        var dataObject = this.props.dataObject;
        var addressBlock = "";
        if (!this.isNullorEmpty(dataObject.locationName)) { addressBlock = addressBlock + dataObject.locationName + ", "; }
        if (!this.isNullorEmpty(dataObject.houseName)) { addressBlock = addressBlock + dataObject.houseName + ", "; }
        if (!this.isNullorEmpty(dataObject.houseNumber)) { addressBlock = addressBlock + dataObject.houseNumber + ", "; }
        if (!this.isNullorEmpty(dataObject.street)) { addressBlock = addressBlock + dataObject.street + ", "; }
        if (!this.isNullorEmpty(dataObject.locality)) { addressBlock = addressBlock + dataObject.locality + ", "; }
        if (!this.isNullorEmpty(dataObject.town)) { addressBlock = addressBlock + dataObject.town + ", "; }
        if (!this.isNullorEmpty(dataObject.county)) { addressBlock = addressBlock + dataObject.county + ", "; }
        if (!this.isNullorEmpty(dataObject.postcode)) { addressBlock = addressBlock + dataObject.postcode + ", "; }
        if (!this.isNullorEmpty(dataObject.country)) { addressBlock = addressBlock + dataObject.country + ", "; }
        return addressBlock;
    }

    async getCoordinates() {

  
        //  Copy current location state from editing fields
        let location = structuredClone(this.props.dataObject);
        var fieldNames = Object.keys(this.formFields);
        fieldNames.map((fieldname) => {
            var field = this.formFields[fieldname];
            if (field.isDirty()) {
                location[field.name] = field.value;
            }
        })

        var postData = JSON.stringify(location);

        var form = document.getElementById("__AjaxAntiForgeryForm");
        if (form != undefined && form.childNodes.length > 0) {
            var antiForgeryToken = form.childNodes[0].value;
            if (antiForgeryToken != undefined) {
                const config = { headers: { 'Content-Type': 'application/json', '__RequestVerificationToken': antiForgeryToken } };
                return baseApi
                    .post(GET_COORDINATES, postData, config)
                    .then((result) => {
                        const data = result.data.data;
                        return data;
                    }).catch((err) => {
                        console.error(err);
                        return null;
                    });
            }
        }
        return null;
    }

    onDelete = () => {
        var property = this.props.property;
        this.props.onDelete(property, this.props.dataObject);
        this.initialiseFormFields();
    }

    render() {

        this.initialiseFormFields();
        var fields = this.formFields;

        if (this.loqateApi == undefined) {
            this.initialiseLoqateApi(fields);
        }

        var buttonStyle = "margin:10px;";
        var buttonClass = "button button--secondary";
        var editMode = this.props.editMode;
        var subformEditMode = this.state.subformEditMode;

        

        var inputHelper = this.state.inputHelper;

        var isDeleted = (fields != undefined && fields.deleted != undefined) ? fields.deleted.value : false;
        var hidden = this.state.isCollapsed ? "hidden" : "";
        var expanded = this.state.isCollapsed ? false : true;
        var accordionTitle = this.getAccordionTitle();
        var accordionId = this.props.property.name + "-" + this.props.dataObject.index;
        var accordionGroupId = "accordion-group-" + accordionId;
        var accordionButtonId = "accordion-" + accordionId;
        var accordionSectionId = "sect-" + accordionId;

        var renderMap = false;
        if (
            (fields != undefined) &&
            (!fields.latitude?.isNullOrEmpty() && !fields.longitude?.isNullOrEmpty() && fields.visibleOnMap.value == true)
        )
        {
            renderMap = true;
        }

        var hasValidationErrors = inputHelper.hasValidationErrors(fields);
        var accordionClass = hasValidationErrors ? "accordion accordion--washed-chalk accordion--invalid" : "accordion accordion--washed-chalk";
        var accordionClass = isDeleted ? "accordion accordion--washed-chalk accordion--deleted" : accordionClass;
        accordionTitle = isDeleted ? accordionTitle + " (deleted)" : accordionTitle;
        expanded = hasValidationErrors ? true : expanded;
        var hidden = expanded ? "" : "hidden";

        return (

            <dl id={accordionGroupId}
                role="presentation"
                class={accordionClass}
                data-dc-accordion=""
                data-allow-multiple=""
                data-allow-toggle="">

                <dt role="heading" aria-level="3">

                    <button type="button" onClick={this.onToggleAccordion}
                        aria-expanded={expanded} class="accordion__trigger"
                        aria-controls={accordionSectionId}
                        id={accordionButtonId} >

                        <h2 class="accordion__title">{accordionTitle}</h2>
                        <span class="accordion__icon">
                            <span></span><span></span>
                        </span>

                    </button>

                </dt>

                {
                    //  edit view 
                    (fields != undefined) &&
                    <dd id={accordionSectionId}
                        role="region"
                        aria-labelledby={accordionButtonId}
                        class="accordion__panel"
                            hidden={hidden}>

                            {
                                this.props.property.childProperties.map((childProperty) => {

                                    var field = inputHelper.getFormFieldByPropertyName(childProperty.name, fields);
                                    return (inputHelper.autoGenerateInputField(field, childProperty))
                                    
                                })
                            }

                        {
                            (renderMap) &&
                            <div style="padding:5px;">
                                <Map
                                    id={this.props.id + "_map"}
                                    latitude={fields.latitude.value}
                                    longitude={fields.longitude.value}
                                    onMarkerMoved={this.onMapMarkerMoved}
                                    streetViewControl={false}
                                    mapTypeControl={false}
                                    fullscreenControl={false}
                                    width="100%"
                                    height={300}
                                    apiKey={this.props.mapApiKey}
                                />
                            </div>
                        }


                        {
                            (isDeleted != true) &&
                            <div class="entity-row-controls">
                                <button style={buttonStyle} type="button" class={buttonClass} onClick={this.onDelete}>Delete location</button>
                            </div>
                        }

                        {
                            (isDeleted == true) &&  
                            <div class="entity-row-controls">
                                <button style={buttonStyle} type="button" class={buttonClass} onClick={this.onDelete}>Un-delete location</button>
                            </div>
                        }

      
                    </dd>
                }

             

            </dl>

        );
    }   

}

