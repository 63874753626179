import { BaseComponent } from 'project/general';
import { notificationService } from 'project/services';
import { NotificationItem } from './notidication-item';

const NOTIFICATION_TIMEOUT = 5000;

class NotificationsComponent extends BaseComponent {
    constructor(element) {
        super(element);
        this.items = {};
    }

    static getNamespace() {
        return 'notifications';
    }

    onInit() {
        notificationService.subscribeOnUpdate(this.updateNotificationView);
        this.updateNotificationView(notificationService.getState());
    }

    updateNotificationView = (state) => {
        if (!state) return;

        const stateKeys = Object.keys(state);
        stateKeys.forEach((id) => {
            if (this.items[id] === undefined) {
                this.createNotification(state[id]);
            }
        });

        const itemKeys = Object.keys(this.items);
        itemKeys.forEach((id) => {
            if (state[id] === undefined) {
                this.removeNotification(id);
            }
        });
    };

    removeNotification(id) {
        const itemElement = this.items[id].getElement();
        this.items[id].destroy();
        this.element.removeChild(itemElement);
        delete this.items[id];
    }

    createNotification(notificationObject) {
        const id = notificationObject.id;
        const item = new NotificationItem(notificationObject,
            {
                closeCallBack: this.onItemClose,
                timeout: NOTIFICATION_TIMEOUT,
                timeoutCallBack: this.onTimeout
            });
        const itemElement = item.getElement();
        this.element.appendChild(itemElement);
        this.items[id] = item;
    }

    onItemClose = (id) => {
        notificationService.remove(id);
    };

    onTimeout = (id) => {
        notificationService.remove(id);
    };
}

export default NotificationsComponent;
