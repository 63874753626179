import React from 'react';
import { Fragment } from 'preact';
import Quill from 'quill';

export class HtmlInput extends React.Component {

    state = { quill: undefined, editorHtml: this.props.field.value}

    onValueChanged = () => {

        var html = this.state.quill.getSemanticHTML();

        html = html.replace("<p></p>", "");//  remove empty paragraphs if present

        this.props.field.setValue(html, this.props.userEditKey);
        var inputElement = document.getElementById(this.props.field.inputElementId);
        inputElement.value = html;
        this.props.onValueChanged(this.props.field);
    }

    componentDidMount() {

        const toolbarOptions = [{ 'list': 'bullet' }];

        if (this.state.editorHtml != undefined) {
            this.editorContainer.innerHTML = this.state.editorHtml;
        }

        this.state.quill = new Quill(this.editorContainer, {
            theme: 'snow',
            formats: ['link', 'list'],  // limits what can be pasted
            modules: {
                toolbar: toolbarOptions
            }
        });

        this.editorContainer.addEventListener("focusout", this.onValueChanged);

        this.setState({ quill: this.state.quill });

    }

    render() {


        return (
            <Fragment>
                {
                    (this.props.editMode == true) &&
                    <Fragment>

                        {/* quill uses a 'contenteditable' div as the text editor, it won't get posted to the server */}
                            <div ref={el => (this.editorContainer = el)} class={this.props.cssClass} />

                        {/* the hidden field gets posted to the server */}
                        <input type="hidden" id={this.props.field.inputElementId} name={this.props.field.inputElementName} value={this.props.field.value} />

                    </Fragment>
                }
                {
                    (this.props.editMode == false) &&
                    this.props.field.value
                }
            </Fragment>
        );
    }

}
