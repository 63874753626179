//import "preact/debug";

import { NasFormComponent } from 'shared/nas-form';
import { ServiceEditorService } from 'project/services';

// Note : this is not a React Component - it creates them
export default class ServiceEditorComponent extends NasFormComponent {

    serviceCategories = null;
    steps = null;

    static getNamespace() {
        return 'service-editor-component';
    }

    static getRequiredRefs() {
        return ['main_details_placeholder'];
    }

    onInit() {

        this.editMode = false;

            //  Load the option lists the user will need to see
            ServiceEditorService.getServiceCategoryOptions().then((result) => {
                if (result != undefined) {

                    this.serviceCategories = result;

                    var nodeGUID = this.element.getAttribute("data-dc-service-editor-component-nodeGuid");

                    if (nodeGUID != undefined && nodeGUID != "") {

                        //  Load the service page data and give it to the form
                        ServiceEditorService.getServiceListingRequest(nodeGUID).then((result) => {
                            if (result != undefined) {

                                this.onDataObjectLoaded(result);

                                //  Render the UI
                                this.renderForm(this.editMode);
                            }
                        });

                    }
                    else {
                        //  Render the UI
                        this.renderForm(this.editMode);
                    }

                }
            });
        }

    createWizardSteps = () => {

        var steps = [];

        var step = {
            name: "Basic details", properties:
                [
                    {
                        name: "id",
                        displayName: "ID",
                        inputType: "HiddenInput",
                    },
                    {
                        name: "nodeGuid",
                        displayName: "nodeGuid",
                        inputType: "HiddenInput"
                    },
                    {
                        name: "title",
                        displayName: "Title",
                        inputType: "TextInput",
                        required: true,
                        tooltip: "Please enter your organisation or service name (less than 200 characters)."
                    },
                    {
                        name: "serviceUrl",
                        displayName: "Service URL",
                        inputType: "TextInput",
                        required: true,
                        tooltip: "Please include http:// or https://. If you have a web page that specifically details how you tailor your service for autistic people, please use that URL."
                    },
                    {
                        name: "secondaryUrl",
                        displayName: "Secondary URL",
                        inputType: "TextInput",
                        tooltip: "Please include http:// or https://. If you have a web page that specifically details how you tailor your service for autistic people, please use that URL."
                    },
                    {
                        name: "autismFriendlyAwardUrl",
                        displayName: "Autism friendly award URL",
                        inputType: "TextInput",
                        tooltip: "Please include http:// or https://."
                    },
                    {
                        name: "onlineTelephone",
                        displayName: "Online / telephone service",
                        inputType: "BooleanInput",
                        tooltip: "Please tick this if your service has elements that are delivered online or by telephone."
                    },
                    {
                        name: "mainContent",
                        displayName: "Main content",
                        inputType: "HtmlInput",
                        required: true,
                        tooltip: "Enter the main description for your service here. This is what will be seen by members of the public when they view your listing. Please include how your service is tailored for autistic people."
                    },
                    {
                        name: "description",
                        displayName: "Short description",
                        inputType: "TextAreaInput",
                        required: true,
                        cols: 40,
                        rows: 3,
                        tooltip: "Enter a short description for your service here. This is what will be seen by members of the public when your listing is shown in a list of services."
                    },
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Service categories", properties:
                [
                    {
                        name: "industrySector",
                        displayName: "Industry sector",
                        inputType: "DropDownInput",
                        required: true,
                        options: this.serviceCategories.industrySectors
                    },
                    {
                        name: "serviceCategories",
                        displayName: "Service categories",
                        inputType: "MultiSelectInput",
                        required: true,
                        options: this.serviceCategories.serviceCategories,
                        tooltip: "Please choose the category(s) which best fits your service."
                    },
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Eligibility", properties:
                [
                    {
                        name: "ageFrom",
                        displayName: "Age from",
                        inputType: "NumberInput",
                        tooltip: "Please enter the age of the people your service is designed to support, if any age please enter 0-99."
                    },
                    {
                        name: "ageTo",
                        displayName: "Age to",
                        inputType: "NumberInput"
                    },
                    {
                        name: "aimedAt",
                        displayName: "Aimed at",
                        inputType: "MultiSelectInput",
                        options: this.serviceCategories.aimedAt,
                        tooltip: "The default is all categories. Only choose categories if your service is restricted to those categories."
                    },
                    {
                        name: "eligibilityNote",
                        displayName: "Eligibility note",
                        inputType: "TextAreaInput",
                        cols: 40,
                        rows: 5,
                        tooltip: "Please include any other information on who is eligible to use your service."
                    },
                    {
                        name: "referralSources",
                        displayName: "Referral sources",
                        inputType: "MultiSelectInput",
                        options: this.serviceCategories.referralSources,
                        tooltip: "Please select the ways in which people can access your service."
                    },
                    {
                        name: "coverage",
                        displayName: "Coverage",
                        inputType: "MultiSelectInput",
                        options: this.serviceCategories.coverage,
                        tooltip: "The default coverage is all areas. Only choose areas if your service is restricted to covering those areas."
                    }
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Specialisms", properties:
                [
                    {
                        name: "specialisms",
                        displayName: "Specialisms",
                        inputType: "MultiSelectInput",
                        options: this.serviceCategories.specialisms
                    },
                    {
                        name: "otherSpecialisms",
                        displayName: "Other specialisms",
                        inputType: "TextAreaInput",
                        cols: 40,
                        rows: 5
                    },
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Relationships with other services", properties:
                [
                    {
                        name: "provides",
                        displayName: "Are you a parent/controlling or a holding company?",
                        inputType: "OptionPickerWithSearch",
                        searchMethod: ServiceEditorService.findServicesByName,
                        selectedItemsLabel: "Selected services",
                        tooltip: "If yes, please enter the name of a subsidiary organisation that is currently listed on our Directory."
                    },
                    {
                        name: "provider",
                        displayName: "Are you a subsidiary organisation",
                        inputType: "OptionPickerWithSearch",
                        searchMethod: ServiceEditorService.findServicesByName,
                        selectedItemsLabel: "Selected services",
                        tooltip: "If yes, please enter the name of your parent/controlling or holding organisation that is currently listed on our Directory."
                    },
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Regulatory and professional certifications", properties:
                [
                    {
                        name: "registrationsAndProfessionalQuals",
                        displayName: "Registrations And Professional Qualifications (obsolete)",
                        inputType: "TextInput",
                        tooltip: "Replaced by regulatory and professional certifications.",
                        allowEdit: false
                    },
                    {
                        name: "approachesAndToolsUsed",
                        displayName: "Approaches and tools used  (obsolete)",
                        inputType: "TextInput",
                        tooltip: "Replaced by regulatory and professional certifications.",
                        allowEdit: false
                    },
                    {
                        name: "certifications",
                        displayName: "Regulatory and professional certifications",
                        inputType: "EntityRows",
                        tooltip: "This replaces registrations and professional qualifications / approaches and tools used.",
                        onAdd: this.onAddChildRow,
                        onDelete: this.onDeleteChildRow,
                        onCreateFormFields: this.onCreateFormFields,
                        childProperties: [
                            { "name": "certificationID", "displayName": "certification id", inputType: "HiddenInput", parentPropertyName: "certifications" },
                            { "name": "type", "displayName": "Type", inputType: "RadioButtonInput", parentPropertyName: "certifications", options: this.serviceCategories.certificationTypes, tooltip:"Please select those relevant to your service. Where available please include the weblink to the awarding bodies website detailing your service." },
                            { "name": "body", "displayName": "Body", inputType: "TextInput", parentPropertyName: "certifications" },
                            { "name": "url", "displayName": "Url", inputType: "TextInput", parentPropertyName: "certifications", tooltip:"Please include http:// or https://." },
                            { "name": "deleted", "displayName": "Delete certification", inputType: "HiddenInput", parentPropertyName: "certifications" },
                            { "name": "index", "displayName": "index", inputType: "HiddenInput", parentPropertyName: "certifications" }
                        ]
                    }
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Contacts", properties:
                [
                    {
                        name: "preferredContactMethods",
                        displayName: "Preferred methods of contact",
                        inputType: "MultiSelectInput",
                        required: true,
                        options: this.serviceCategories.contactMethods,
                        tooltip: "Please choose all methods of contact you would like those accessing your service to use."
                    },
                    {
                        name: "contacts",
                        displayName: "Contacts",
                        inputType: "EntityRows",
                        onAdd: this.onAddChildRow,
                        onDelete: this.onDeleteChildRow,
                        onCreateFormFields: this.onCreateFormFields,
                        childProperties: [
                            { "name": "contactNodeGuid", "displayName": "id", inputType: "HiddenInput", parentPropertyName: "contacts" },
                            { "name": "name", "displayName": "Name", inputType: "TextInput", parentPropertyName: "contacts", tooltip: "Please enter the full name of your contact." },
                            { "name": "jobTitle", "displayName": "Job title", inputType: "TextInput", parentPropertyName: "contacts", tooltip: "Please enter the job title of your contact e.g. Headteacher; Office Manager." },
                            { "name": "telephone", "displayName": "Telephone", inputType: "TextInput", parentPropertyName: "contacts" },
                            { "name": "email", "displayName": "Email", inputType: "TextInput", parentPropertyName: "contacts" },
                            { "name": "webLinkExternal", "displayName": "Web link", inputType: "TextInput", parentPropertyName: "contacts" },
                            { "name": "isPrimary", "displayName": "Primary contact", inputType: "BooleanInput", required: true, parentPropertyName: "contacts" },
                            { "name": "visibleOnService", "displayName": "Visible on service page", inputType: "BooleanInput", required: true, defaultValue: true, parentPropertyName: "contacts" },
                            { "name": "deleted", "displayName": "Delete contact", inputType: "HiddenInput", parentPropertyName: "contacts" },
                            { "name": "index", "displayName": "index", inputType: "HiddenInput", parentPropertyName: "contacts" }
                        ]
                    }
 
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Locations", properties:
                [
                    {
                        name: "locations",
                        displayName: "Locations",
                        inputType: "LocationFields",
                        onAdd: this.onAddChildRow,
                        onDelete: this.onDeleteChildRow,
                        onCreateFormFields: this.onCreateFormFields,
                        childProperties: [
                            { "name": "locationNodeGuid", "displayName": "id", inputType: "HiddenInput", parentPropertyName: "locations" },
                            { "name": "name", "displayName": "Location name", inputType: "TextInput", required: true, parentPropertyName: "locations", tooltip: "The name of this location in the list of location for this service, e.g. 'Head office'." },
                            { "name": "postcode", "displayName": "Postcode / address lookup", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "houseName", "displayName": "House name", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "houseNumber", "displayName": "House number", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "street", "displayName": "Street", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "locality", "displayName": "Locality", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "town", "displayName": "Town", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "county", "displayName": "County", inputType: "TextInput", parentPropertyName: "locations" },
                            { "name": "visibleOnService", "displayName": "Visible on service page", inputType: "BooleanInput", defaultValue: true, parentPropertyName: "locations" },
                            { "name": "visibleOnMap", "displayName": "Visible on map", inputType: "BooleanInput", defaultValue: true, parentPropertyName: "locations", tooltip: "If selected, the service will be shown on the search results map, and also the service page itself." },
                            { "name": "country", "displayName": "Country", inputType: "HiddenInput", parentPropertyName: "locations" },
                            { "name": "latitude", "displayName": "Latitude", inputType: "HiddenInput", defaultValue: 54.028411, parentPropertyName: "locations" },
                            { "name": "longitude", "displayName": "Longitude", inputType: "HiddenInput", defaultValue: -1.940000, parentPropertyName: "locations" },
                            { "name": "deleted", "displayName": "Delete location", inputType: "HiddenInput", parentPropertyName: "locations" },
                            { "name": "index", "displayName": "index", inputType: "HiddenInput", parentPropertyName: "locations" }
                        ]
                    }
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);

        var step = {
            name: "Terms & Conditions", properties:
                [
                    {
                        name: "agreedTermsAndConditions",
                        displayName: "Terms and conditions",
                        inputType: "MultiSelectInput",
                        options: this.serviceCategories.termsAndConditions,
                        tooltip: "Please tick all items.",
                        required: true
                    }
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);
        steps.push(step);
        return steps;
    }

}
