import { Modal } from 'modal';

const COOKIE_SETTINGS_ID = 'cookie-settings';

class CookieSettingsModal {
    constructor() {
        this.modal = null;
        this.cookieSettings = document.querySelector(`#${COOKIE_SETTINGS_ID}`);

        this.modalOptions = {
            bemList: ['tiny'],
            autoCloseOnClickOutside: false,
        };
    }

    open() {
        if (this.cookieSettings) {
            this.modal = Modal.create(this.cookieSettings, this.modalOptions);
            this.modal.open();
        }
    }

    close() {
        if (this.modal) {
            this.modal.close();
        }
    }

    destroy() {
        if (this.modal) {
            this.modal.destroy();
        }
    }
}

const instance = new CookieSettingsModal();
export default instance;
