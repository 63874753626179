import React from 'react';
import { Fragment } from 'preact';
import { MultiSelectBase } from './multi-select-base.jsx';
export class MultiSelectDropdown extends MultiSelectBase {

    state = { iconClass: "dropdown-icon", submenuClass: "form-field dropdown__submenu" }

    componentDidMount() {

        this.detectSelectedItem();
    }

    onDropdownIconClick = () => {

        if (this.state.iconClass.indexOf('active') > 0) {
            this.state.iconClass = this.state.iconClass.replace('active', '');
        } else {
            this.state.iconClass += ' active';
        }

        if (this.state.submenuClass.indexOf('active') > 0) {
            this.state.submenuClass = this.state.submenuClass.replace('active', '');
        } else {
            this.state.submenuClass += ' active';
        }

        this.setState({
            iconClass: this.state.iconClass,
            submenuClass: this.state.submenuClass
        });

    }

    detectSelectedItem = () => {

        var treeViewItem = this.props.treeViewItem;

        for (var i = 0; i < treeViewItem.children.length; i++) {

            var option = treeViewItem.children[i];
            var isOptionSelected = this.props.field.isOptionSelected(option);

            if (isOptionSelected) {

                var val = this.state.iconClass.indexOf('active');

                if (this.state.iconClass.indexOf('active') == -1) {
                    this.state.iconClass += ' active';
                }
                if (this.state.submenuClass.indexOf('active') == -1) {
                    this.state.submenuClass += ' active';
                }

            }
        }

    }
 
    render() {
        var fieldName = this.props.field.name + "#" + this.props.field.dataObjectId;
        var treeViewItem = this.props.treeViewItem;

        return (
            <Fragment>
  
                <div class="dropdown">

                    <label class="checkbox-label" style="padding-left:0px;">{treeViewItem.displayName}</label>

                    <a data-dc-filter-select-ref="button" class="dropdown-icon-holder">
                        <div class={this.state.iconClass} onClick={this.onDropdownIconClick}  data-dc-filter-select-ref="icon">
                            <span></span><span></span>
                        </div>
                    </a>

                    <div class={this.state.submenuClass}>

                        {treeViewItem.children.map((childOption) => {

                            //  If this option has children, create a multi-select dropdown for the child options
                            if (childOption.children != undefined && childOption.children.length > 0) {
                                return (
                                    <MultiSelectDropdown
                                        field={this.props.field}
                                        treeViewItem={childOption}
                                        userEditKey={this.props.userEditKey}
                                        name={this.props.field.inputElementName}
                                        onValueChanged={this.props.onValueChanged}
                                    />
                                )
                            }

                            //  If this option has no children, create a single checkbox for the option
                            else {
                                var inputCss = this.props.field.isValid() ? "form-field__checkbox" : "form-field__checkbox is-invalid";
                                var checkMarkCss = this.props.field.isValid() ? "checkmark" : "checkmark is-invalid";
                                var isOptionSelected = this.props.field.isOptionSelected(childOption);
                                var checkBoxId = this.createCheckboxId(childOption);
                                return (
                                    <Fragment>
                                        <label for={checkBoxId} class="checkbox-label">
                                            {childOption.displayName}
                                            <input type="checkbox"
                                                id={checkBoxId}
                                                name={this.props.field.inputElementName + "[]"}
                                                value={childOption.id}
                                                checked={isOptionSelected ? "checked" : ""}
                                                aria-checked={isOptionSelected ? "True" : "False"}
                                                onChangeCapture={this.onItemClick}
                                                aria-label={childOption.displayName}
                                                class={inputCss}
                                                />
                                            <span class={checkMarkCss} ></span>
                                        </label>
                                    </Fragment>
                                )
                            }


                        })}
                    </div>

                </div>

            </Fragment>
                
        );
    }

}
