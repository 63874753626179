import React from 'react';

export class SubformController extends React.Component {

    onReset = () => {
        //  Set location databack to the initial values
        if (this.state.formFields != undefined) {
            let dataObject = this.props.dataObject;
            var fieldNames = Object.keys(this.state.formFields);
            for (var i = 0; i < fieldNames.length; i++) {
                var field = this.state.formFields[fieldNames[i]];
                if (field.isDirty()) {
                    field.revertToOriginalValue();
                    dataObject[field.name] = field.value;
                }
            }
        }
        this.setState({ formFields: this.state.formFields });
        this.setState({ subformEditMode: false });
    }

    isDirty() {
        if (this.state.formFields != undefined) {
            var fieldNames = Object.keys(this.state.formFields);
            for (var i = 0; i < fieldNames.length; i++) {
                var field = this.state.formFields[fieldNames[i]];
                if (field.isDirty()) {
                    return true;
                }
            }
        }
        return false;
    }

    isNullorEmpty(value) {
        if (value == undefined) {
            return true;
        }
        else if (value == "") {
            return true;
        }
        return false;
    }

}
