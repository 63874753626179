import { BaseForm, formConstants } from 'shared/base-form';
import { BaseComponent } from 'project/general';
import { membershipController } from 'project/membership';
import { EcommerceTracking } from 'project/services';
import { baseApi } from 'project/api';
import { EventExpandedStep } from './event-expanded-step';
import { basketController } from 'project/basket';

export class MembershipFormComponent extends BaseComponent {
    static getNamespace() {
        return 'membership-form';
    }

    onInit() {
        this.initForm();
        this.initEventExpandedStep();
    }

    initEventExpandedStep() {
        if (this.refs.eventExpanded !== undefined) {
            this.eventExpandedInstance = new EventExpandedStep(this.refs.eventExpanded, { formInstance: this.formInstance });
            this.eventExpandedInstance.init();
        }
    }

    initForm() {
        this.formInstance = new BaseForm(this.element, {
            submitElement: this.element.querySelector('button[type="submit"]'),
            errorsSummary: this.element.querySelector('[data-errors-summary]'),
            onSuccessfulSubmit: (res) => {
                if (res.data.success) {
                    if (res.data.data && res.data.data.trackingProduct) {
                        EcommerceTracking.trackAddProduct(res.data.data.trackingProduct);
                    }
                    if (res.data.redirectUrl) {
                        window.location.href = res.data.redirectUrl;
                    }
                    if (res.data.data && res.data.data.html) {
                        membershipController.showModal(res.data.data.html);
                    }
                    if (res.data.data && res.data.data.modalHtml) {
                        membershipController.showModal(res.data.data.modalHtml);
                    }
                    if (res.data.data && res.data.data.basket) {
                        basketController.notifyBasketUpdated(res.data.data.basket);
                    }
                }
            },
            onFailedSubmit: (error) => {
                console.error(error);
            },
            errorsSummaryTemplate: (errors) => {
                return `<div class="alert alert-danger" role="alert">${errors.map((error) => `<div>${error}</div>`).join()}</div>`;
            },
            enctype: formConstants.ENCTYPE_JSON,
            axiosInstance: baseApi,
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<div class="field-validation-error" aria-live="assertive" aria-atomic="true"></div>',
                errorTemplate: '<div></div>',
            }
        });

    }

    onDestroy() {
        this.formInstance.destroy();
        if (this.eventExpandedInstance) {
            this.eventExpandedInstance.destroy();
        }
    }

    getUrlParameterByName(parameterName) {
        var url = window.location.href;
        var name = parameterName.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));  
    }
}
