import './scss/index.scss';
import './js/filter.component';
import { dcFactory } from 'dc';
import FilterSelectComponent from './js/filter.component';
import ThirdLevelFilterSelectComponent from './js/third-level-filter.component';
import SidebarFilterComponent from './js/sidebar-filter.component';
import SidebarToggleComponent from './js/sidebar-toggle.component';

dcFactory.register(FilterSelectComponent);
dcFactory.register(ThirdLevelFilterSelectComponent);
dcFactory.register(SidebarFilterComponent);
dcFactory.register(SidebarToggleComponent);
