import { Modal } from 'modal';
import { dcFactory } from 'dc';
import { pageLocker } from 'page-locker';
import { generateBemCss } from 'project/helpers';
import { Overlay } from 'components/overlay';

const overlay = new Overlay('overlay', 'overlay');

Modal.init({
    onModalInit: (modal) => {
        dcFactory.init(modal.element);
    },
    onModalDestroy: (modal) => {
        dcFactory.destroy(modal.element);
    },
    onBeforeFirstModalOpen: () => {
        pageLocker.lock('modal');
        overlay.show('modal');
    },
    onAfterLastModalClose: () => {
        pageLocker.unlock('modal');
        overlay.hide('modal');
    },
    defaultModalTemplate: ({ bemList }) => {
        const rootCss = generateBemCss('modal', bemList);

        return (`
            <div data-modal class="${rootCss}" aria-hidden="true" role="dialog">
                <div class="modal__wrapper" data-modal-wrapper>
                    <button class="modal__close" data-modal-close type="button" aria-label="close popup">
                        <span class="button-content">
                            <svg class="icon modal__close-icon modal__close-icon--cross" width="10" height="10" focusable="false">
                                <use xlink:href="#icon-cross-bold"></use>
                            </svg>
                        </span>
                    </button>
                    <div class="modal__content" data-modal-content></div>
                </div>
            </div>
        `);
    }

});

export default Modal;
