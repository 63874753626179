import { BaseComponent } from 'project/general';

class ThirdLevelFilterSelectComponent extends BaseComponent {

    static getNamespace() {
        return 'filter-third-level';
    }

    onInit() {
        if (this.refs.button) {
            this.refs.button.addEventListener('click', this.onClick);
        }
    }

    onClick = () => {
        if (this.refs.submenu.classList.contains('active')) {
            this.refs.submenu.classList.remove('active');
        } else {
            this.refs.submenu.classList.add('active');
        }
        if (this.refs.icon.classList.contains('active')) {
            this.refs.icon.classList.remove('active');
        } else {
            this.refs.icon.classList.add('active');
        }
    };


    keyDown = () => {

    };
}

export default ThirdLevelFilterSelectComponent;
