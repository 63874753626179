import { BaseComponent } from 'project/general';
import './owlcarousel2-progressbar';

class RelatedAdviceSliderComponent extends BaseComponent {

    static getNamespace() {
        return 'related-advice-slider';
    }

    onInit() {
        var owlAdvice = $(this.element);
        var owlID = owlAdvice.attr("id");

        $(owlAdvice).owlCarouselProgressBar({
            size: '3px',
            borderRadius: 2.5,
            margin: '5px 0 0',
            foregroundColor: '#d0c0ef',
            color: '#4203bf',
            transitionInterval: 1,
            progressBarClassName: 'scrollbar',
            scrollerClassName: 'scrollbar__scroller'
        });

        owlAdvice.owlCarousel({
            margin: 30,
            nav: false,
            dots: false,
            autoHeight: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 2,
                },
                768: {
                    items: 3,
                },
                964: {
                    items: 4,
                }
            },
            onInitialized: function (event) {
                $(owlAdvice).find('.owl-item').attr('aria-selected', 'false');
                $(owlAdvice).find('.owl-item.active').attr('aria-selected', 'true'); // let screen readers know an item is active

                // apply meta info to next and previous buttons and make them focusable
                $(owlAdvice).parent().find('.owl-buttons__button--owl-next').attr('role', 'button').attr('title', 'Previous');
                $(owlAdvice).parent().find('.owl-buttons__button--owl-next').attr('title', 'Next');
                $(owlAdvice).attr('tabindex', '0');
                $('.owl-buttons__button--owl-next, .owl-buttons__button--owl-prev').attr('tabindex', '0');

                // listen for keyboard input
                $(document).on('keydown', function (e) {

                    var $focusedElement = $(document.activeElement),
                        singleOwl = $(owlAdvice).data('owlCarousel'),
                        type = e.which == 39 ? 'next' : null,
                        type = e.which == 37 ? 'prev' : type,
                        type = e.which == 13 ? 'enter' : type;


                    // if the carousel is focused, use left and right arrow keys to navigate
                    if ($focusedElement.attr('id') === owlID) {
                        if (type == 'next') {
                            owlAdvice.trigger('next.owl.carousel');
                        } else if (type == 'prev') {
                            owlAdvice.trigger('prev.owl.carousel');
                        }

                        // if the prev and next buttons are focused, catch "Enter" and navigate in the right direction
                    } else if (type == 'enter') {
                        if ($focusedElement.attr('id') === owlID + '-next') {
                            owlAdvice.trigger('next.owl.carousel');
                        } else if ($focusedElement.attr('id') === owlID + '-prev') {
                            owlAdvice.trigger('prev.owl.carousel');
                        }
                    }
                });
            }
        });

        // Button actions for next & Prev
        $(owlAdvice).parent().find('#' + owlID + '-next').click(function () {
            owlAdvice.trigger('next.owl.carousel');
        });
        $(owlAdvice).parent().find('#' + owlID + '-prev').click(function () {
            owlAdvice.trigger('prev.owl.carousel');
        });
    }

}

export default RelatedAdviceSliderComponent;
