import { BaseComponent } from 'project/general';

class CollapseMenuComponent extends BaseComponent {

    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    static getNamespace() {
        return 'toggle-menu';
    }

    onInit() {
        let menuItems = this.element.querySelectorAll('li');
        let menuCount = menuItems.length;
        let subMenu = this.refs.submenu;
        if (menuCount > 5) {
            subMenu.classList.add("has-items")
            let subMenuList = document.createElement("ul");
            subMenu.append(subMenuList)
            if (menuItems[5]) {
                subMenuList.append(menuItems[5]);
            }
            if (menuItems[6]) {
                subMenuList.append(menuItems[6]);
            }
            if (menuItems[7]) {
                subMenuList.append(menuItems[7]);
            }
            if (menuItems[8]) {
                subMenuList.append(menuItems[8]);
            }
        }

        this.element.addEventListener('click', this.onClick);
        this.element.addEventListener('keyup', this.keyDown);

    }

    onClick = () => {
        this.refs.submenu.classList.toggle('active');
    };

    keyDown = () => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            if (this.refs.submenu.classList.contains('active')) {
                this.refs.submenu.classList.remove('active');
            } else {
                this.refs.submenu.classList.add('active');
            }
        }
    };
}

export default CollapseMenuComponent;
