import { BaseComponent } from 'project/general';

class AccordionComponent extends BaseComponent {
    static getNamespace() {
        return 'accordion';
    }

    onInit() {
        this.addListener(this.element, 'click', this.onClick);
        this.addListener(this.element, 'keydown', this.onKeydown);
    }

    onClick = () => {

        // Allow for multiple accordion sections to be expanded at the same time
        var allowMultiple = this.element.hasAttribute('data-allow-multiple');
        // Allow for each toggle to both open and close individually
        var allowToggle = (allowMultiple) ? allowMultiple : this.element.hasAttribute('data-allow-toggle');

        // Create the array of toggle elements for the accordion group
        var triggers = Array.prototype.slice.call(this.element.querySelectorAll('.accordion__trigger'));
        var panels = Array.prototype.slice.call(this.element.querySelectorAll('.accordion__panel'));

        var target = event.target;

        if (target.classList.contains('accordion__trigger')) {
            // Check if the current toggle is expanded.
            var isExpanded = target.getAttribute('aria-expanded') == 'true';
            var active = this.element.querySelector('[aria-expanded="true"]');

            // without allowMultiple, close the open accordion
            if (!allowMultiple && active && active !== target) {
                this.collapsePanel(active);

                // When toggling is not allowed, clean up disabled state
                if (!allowToggle) {
                    active.removeAttribute('aria-disabled');
                }
            }

            if (!isExpanded) {
                this.expandPanel(target);

                // If toggling is not allowed, set disabled state on trigger
                if (!allowToggle) {
                    target.setAttribute('aria-disabled', 'true');
                }
            }
            else if (allowToggle && isExpanded) {
                this.collapsePanel(target);
            }

            event.preventDefault();
        }
    };

    onKeydown = () => {
        // Allow for multiple accordion sections to be expanded at the same time
        var allowMultiple = this.element.hasAttribute('data-allow-multiple');
        // Allow for each toggle to both open and close individually
        var allowToggle = (allowMultiple) ? allowMultiple : this.element.hasAttribute('data-allow-toggle');

        // Create the array of toggle elements for the accordion group
        var triggers = Array.prototype.slice.call(this.element.querySelectorAll('.accordion__trigger'));
        var panels = Array.prototype.slice.call(this.element.querySelectorAll('.accordion__panel'));

        var target = event.target;
        var key = event.which.toString();

        var isExpanded = target.getAttribute('aria-expanded') == 'true';
        var allowToggle = (allowMultiple) ? allowMultiple : accordion.hasAttribute('data-allow-toggle');

        // 33 = Page Up, 34 = Page Down
        var ctrlModifier = (event.ctrlKey && key.match(/33|34/));

        // Is this coming from an accordion header?
        if (target.classList.contains('accordion__trigger')) {
            // Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
            // 38 = Up, 40 = Down
            if (key.match(/38|40/) || ctrlModifier) {
                var index = triggers.indexOf(target);
                var direction = (key.match(/34|40/)) ? 1 : -1;
                var length = triggers.length;
                var newIndex = (index + length + direction) % length;

                triggers[newIndex].focus();

                event.preventDefault();
            }
            else if (key.match(/35|36/)) {
                // 35 = End, 36 = Home keyboard operations
                switch (key) {
                    // Go to first accordion
                    case '36':
                        triggers[0].focus();
                        break;
                    // Go to last accordion
                    case '35':
                        triggers[triggers.length - 1].focus();
                        break;
                }
                event.preventDefault();
            }
        }
    };

    collapsePanel = (trigger) => {
        // Set the expanded state to be false on the triggering element
        trigger.setAttribute('aria-expanded', 'false');
        // Hide the accordion sections, using aria-controls to specify the desired section
        document.getElementById(trigger.getAttribute('aria-controls')).setAttribute('hidden', '');
    };

    expandPanel = (trigger) => {
        // Set the expanded state on the triggering element
        trigger.setAttribute('aria-expanded', 'true');
        // Show the accordion sections, using aria-controls to specify the desired section
        document.getElementById(trigger.getAttribute('aria-controls')).removeAttribute('hidden');
    }
}

export default AccordionComponent;
