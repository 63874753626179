import { BaseComponent } from 'project/general';

class RegionSelectorComponent extends BaseComponent {

    static getNamespace() {
        return 'region-selector';
    }

    onInit() {
        this.refs.close.addEventListener('click', this.onClick);
        this.refs.close.addEventListener('keyup', this.keyDown);
    }

    onClick = () => {
        this.element.classList.toggle('close');
    };

    keyDown = () => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            if (this.element.classList.contains('close')) {
                this.element.classList.remove('close');
            } else {
                this.element.classList.add('close');
            }
        }
    };


}

export default RegionSelectorComponent;
