import { BaseComponent } from 'project/general';

class TextImageCarouselComponent extends BaseComponent {

    static getNamespace() {
        return 'text-image-slider';
    }

    onInit() {
        var owlTextCarousel = $(this.element);
        var owlID = owlTextCarousel.attr("id");
        owlTextCarousel.owlCarousel({
            margin: 30,
            nav: false,
            dots: false,
            autoHeight: true,
            smartSpeed: 1000,
            loop: false,
            mouseDrag: false,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 1,
                },
                1000: {
                    items: 1
                }
            },
            onInitialized: function (event) {
                $(owlTextCarousel).find('.owl-item').attr('aria-selected', 'false');
                $(owlTextCarousel).find('.owl-item.active').attr('aria-selected', 'true'); // let screen readers know an item is active

                // apply meta info to next and previous buttons and make them focusable
                $(owlTextCarousel).parent().find('.owl-buttons__button--owl-next').attr('role', 'button').attr('title', 'Previous');
                $(owlTextCarousel).parent().find('.owl-buttons__button--owl-next').attr('title', 'Next');
                $(owlTextCarousel).attr('tabindex', '0');
                $('.owl-buttons__button--owl-next, .owl-buttons__button--owl-prev').attr('tabindex', '0');

                // listen for keyboard input
                $(document).on('keydown', function (e) {

                    var $focusedElement = $(document.activeElement),
                        singleOwl = $(owlTextCarousel).data('owlCarousel'),
                        type = e.which == 39 ? 'next' : null,
                        type = e.which == 37 ? 'prev' : type,
                        type = e.which == 13 ? 'enter' : type;


                    // if the carousel is focused, use left and right arrow keys to navigate
                    if ($focusedElement.attr('id') === owlID) {
                        if (type == 'next') {
                            owlTextCarousel.trigger('next.owl.carousel');
                        } else if (type == 'prev') {
                            owlTextCarousel.trigger('prev.owl.carousel');
                        }

                        // if the prev and next buttons are focused, catch "Enter" and navigate in the right direction
                    } else if (type == 'enter') {
                        if ($focusedElement.attr('id') === owlID + '-next') {
                            owlTextCarousel.trigger('next.owl.carousel');
                        } else if ($focusedElement.attr('id') === owlID + '-prev') {
                            owlTextCarousel.trigger('prev.owl.carousel');
                        }
                    }
                });
            }
        });
        // Button actions for next & Prev
        $('#' + owlID + ' .owl-buttons__button--owl-next').click(function () {
            owlTextCarousel.trigger('next.owl.carousel');
        });
        $('#' + owlID + ' .owl-buttons__button--owl-prev').click(function () {
            owlTextCarousel.trigger('prev.owl.carousel');
        });
    }

}

export default TextImageCarouselComponent;
