export const trackingTypes = Object.freeze({
    EVENT: 'event',
    ECOMMERCE_IMPRESSION: 'impression',
    ANY: 'any'
});

export const triggers = Object.freeze({
    CLICK: 'click',
    APPEARANCE: 'appearance'
});
