import { CookieService } from 'project/services';
import { BaseComponent } from 'project/general';
import { cookieSettingsModal } from 'components/cookie-settings';

export default class CookieSettingsComponent extends BaseComponent {
    static getNamespace() {
        return 'cookie-settings';
    }

    static getRequiredRefs() {
        return [
            'loadtitle',
            'form',
            'functional',
            'analytics',
            'thirdParty',
            'submit'
        ];
    }

    onInit() {
        let loadTitle = this.refs.loadtitle;
        let mainForm = this.refs.form;
        this.addListener(this.refs.functional, 'change', this._onChangeFunctional);
        this.addListener(this.refs.analytics, 'change', this._onChangeAnalytics);
        this.addListener(this.refs.thirdParty, 'change', this._onChangeThirdParty);
        this.addListener(this.refs.form, 'submit', this._onSubmit);
        CookieService.subscribeOnCookieLevelChanged(this._updateUi);

        setTimeout(function () {
            loadTitle.style.display = 'none'
            mainForm.style.display = 'block'
        }, 3000); // for 3 seconds
        CookieService.loadCookieLevel().then((result) => {
            this.onCookieLevelLoaded(result.data.data);
        });
    }
    onCookieLevelLoaded(cookieLevelData) {

        var userCookieLevel = cookieLevelData.userCookieLevel;
        var defaultCookieLevel = cookieLevelData.defaultCookieLevel;

        if (userCookieLevel == undefined || userCookieLevel === null || userCookieLevel === '') {
            CookieService.setCookieLevel(defaultCookieLevel);

            var event = document.createEvent("HTMLEvents");
            event.initEvent('default-cookie-level-set', true, true);
            event.eventName = 'default-cookie-level-set';
            document.dispatchEvent(event);
        }
        else {
            CookieService.setCookieLevel(userCookieLevel);
        }

        CookieService.sendGtmEvent();
    }

    onDestroy() {
        cookieSettingsModal.destroy();
    }

    _onChangeFunctional = () => {
        if (!this.refs.functional.checked) {
            this.refs.analytics.checked = false;
            this.refs.thirdParty.checked = false;
        }
    };

    _onChangeAnalytics = () => {
        if (!this.refs.analytics.checked) {
            this.refs.thirdParty.checked = false;
        } else {
            this.refs.functional.checked = true;
        }
    };

    _onChangeThirdParty = () => {
        if (this.refs.thirdParty.checked) {
            this.refs.functional.checked = true;
            this.refs.analytics.checked = true;
            this.refs.thirdParty.checked = true;
        }
        localStorage.setItem('socialSetting', this.refs.thirdParty.checked);
    };

    _updateUi = (level) => {
        this._setLevel(level);
    }

    _onSubmit = (e) => {
        e.preventDefault();
        CookieService.saveCookieLevel(this._getLevel());
        cookieSettingsModal.close();
        return false;
    };

    _getControls = () => {
        return [
            this.refs.necessary,
            this.refs.thirdParty,
            this.refs.functional,
            this.refs.analytics
        ];
    };

    _getLevel = () => {
        let level = -1000;
        this._getControls().forEach((x) => {
            if (x.checked && Number(x.value) > level) {
                level = Number(x.value);
            }
        });
        return level;
    };

    _setLevel = (level) => {
        if (level != null) {
            this._getControls()
                .forEach((x) => {
                    x.checked = Number(x.value) <= level;
                });
        }
    };

}
