import { BaseComponent } from 'project/general';
import './jquery.ellipsis.min.js';

class TextTruncationDesktopComponent extends BaseComponent {

    static getNamespace() {
        return 'text-truncation-desktop';
    }

    onInit() {
        const titleTag = this.element
        $(window).on("load resize", function (e) {
            var bodyWidth = $('body').outerWidth();

            if (bodyWidth > 768) {
                $(titleTag).ellipsis({
                    lines: 3, // default: 'auto',
                    responsive: true
                });
            }
            else {
                $(titleTag).ellipsis({
                    responsive: false
                });
            }

        });
    }

}

export default TextTruncationDesktopComponent;
