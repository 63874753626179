import { BaseComponent } from 'project/general';

class SidebarToggleComponent extends BaseComponent {

    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    static getNamespace() {
        return 'sidebar-toggle';
    }

    onInit() {
        this.refs.button.addEventListener('click', this.onClick);
    }

    onClick = () => {
        if (this.element.classList.contains('active')) {
            this.element.classList.remove('active');
        } else {
            this.element.classList.add('active');
        }
        if (document.body.contains(this.refs.icon)) {
            if (this.refs.icon.classList.contains('active')) {
                this.refs.icon.classList.remove('active');
            } else {
                this.refs.icon.classList.add('active');
            }
        }
    };

}

export default SidebarToggleComponent;
