
export class NasFormFunctions {

    constructor() {
    }

    getOptionById(id, option) {


        if (option.id != undefined && option.id == id) {

            return option;

        }

        else if (option.children != undefined) {

            for (var i = 0; i < option.children.length; i++) {

                var childOption = option.children[i];

                if (childOption.id == id) {
                    return childOption;
                }

                else if (childOption.children != undefined) {
                    var childOption = this.getOptionById(id, childOption);
                    if (childOption != undefined) {
                        return childOption;
                    }
                }
            }

        }

        return undefined;

    }

    markOptionSelected(name, option, deselectOtherOptions) {
        if (option.name == name) {
            option.selected = true;
        }
        else if (deselectOtherOptions && option.selected) {
            option.selected = false;
        }
        else if (option.children != undefined) {

            for (var i = 0; i < option.children.length; i++) {

                var childOption = option.children[i];
                this.markOptionSelected(name, childOption, deselectOtherOptions);
            }
        }
    }
}
