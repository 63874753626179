import { BaseComponent } from 'project/general';
import { gtmService } from 'project/services';

export class AccountSelectorComponent extends BaseComponent {
    static getNamespace() {
        return 'account-selector';
    }

    static getRequiredRefs() {
        return ['select'];
    }

    onInit() {
        this.addListener(this.refs.select, 'change', this.onChange);
        this.setCurrentOption();
    }

    onChange = () => {
        const selectedIndex = this.refs.select.selectedIndex;
        const selectedOption = [...this.refs.select][selectedIndex];

        this.sendToGtm(selectedOption.text);

        if (selectedOption.value) {
            window.location.href = selectedOption.value;
        }
    };

    setCurrentOption() {
        const currentOption = [...this.refs.select.options].find((option) => option.classList.contains('current'));

        if (currentOption) {
            currentOption.setAttribute('selected', '');
        }
    }

    sendToGtm(label) {
        if (!label) return;

        gtmService.pushEvent('Manage Profile', 'Click', `${label}`);
    }
}
