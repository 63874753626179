import React from 'react';
import { ArrayHelper } from 'foundation/helpers';
import { OrderSummary } from 'shared/order-summary';
import { BasketCard } from 'shared/basket-card';
import { AccountOrdersAccordion } from './account-orders-accordion';

export class AccountOrdersList extends React.Component {
    render() {
        const { products } = this.props;

        if (ArrayHelper.isEmpty(products)) return null;

        return (
            <ul className="account-orders-list">
                {
                    products
                        .map((product, index) => {
                            const model = {
                                name: product.name,
                                date: product.date,
                                status: product.status,
                                deliveryAddress: product.deliveryAddress,
                                billingAddress: product.billingAddress,
                                orderSummary: product.orderSummary,
                                basketItems: product.basketItems,
                                accordionId: `accordion-order-${index}`,
                                ariaLabelledby: `accordion-order-label-${index}`,
                                isExpanded: index === 0,
                            };
                            return (
                                <li className="account-orders-list__item" key={model.name}>
                                    <AccountOrdersAccordion
                                        id={model.accordionId}
                                        ariaLabelledby={model.ariaLabelledby}
                                        childCss={'account-orders-accordion'}
                                        isExpanded={model.isExpanded}
                                        trigger={
                                            <>
                                                <span className="account-orders-accordion__head-main">
                                                    <span className="account-orders-accordion__title heading-4">{model.name}</span>
                                                    <time className="account-orders-accordion__date" dateTime={model.date}>{model.date}</time>
                                                </span>
                                                <span className="account-orders-accordion__status">{model.status}</span>
                                            </>
                                        }
                                        content={
                                            <>
                                                <div className="account-orders-accordion__address-wrapper grid">
                                                    <div className="row">
                                                        <div className="account-orders-accordion__col col col-xs-12 col-sm-6">
                                                            <span className="account-orders-accordion__subtext subtext-2">Delivery Address</span>
                                                            <div className="account-orders-accordion__address">{model.deliveryAddress}</div>
                                                        </div>
                                                        <div className="account-orders-accordion__col col col-xs-12 col-sm-6">
                                                            <span className="account-orders-accordion__subtext subtext-2">Billing Address</span>
                                                            <div className="account-orders-accordion__address">{model.billingAddress}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="account-orders-accordion__details">
                                                    {
                                                        model.basketItems
                                                            .map((item) => {
                                                                return (
                                                                    <div className="account-orders-accordion__basket-card" key={item.id}>
                                                                        <BasketCard
                                                                            product={item}
                                                                            isEditable={false}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                    }
                                                    <div className="account-orders-accordion__summary">
                                                        <OrderSummary summary={model.orderSummary} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />
                                </li>
                            );
                        })
                }
            </ul>
        );
    }
}
