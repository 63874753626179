import React from 'react';
import { FormFieldLabel } from './form-field-label.jsx';
import { TextInput } from './text-input.jsx';
import { NumberInput } from './number-input.jsx';
import { TextAreaInput } from './text-area-input.jsx';
import { HtmlInput } from './html-input.jsx';
import { HiddenInput } from './hidden-input.jsx';
import { BooleanInput } from './boolean-input.jsx';
import { HyperlinkInput } from './hyperlink-input.jsx';
import { MultiSelectInput } from './multi-select/multi-select-input.jsx';
import { DropDownInput } from './drop-down-input.jsx';
import { RadioButtonInput } from './radio-button-input.jsx';
import { OptionPickerWithSearch } from './option-picker-with-search.js';
import { LocationFields } from '../sub-forms/location-fields.jsx';
import { EntityRows } from '../sub-forms/entity-rows.jsx';

export class InputHelper {

    #editMode;
    #userEditKey;
    #onValueChanged;
    #inputCssClass;
    #mapApiKey;

    constructor(editMode, userEditKey, onValueChanged, inputCssClass, mapApiKey) {

        if (editMode == undefined) {
            console.error("editMode must be defined.");
        }
        else if (onValueChanged == undefined) {
            console.error("onValueChanged must be defined.");
        }
        else {
            this.#editMode = structuredClone(editMode);
            this.#userEditKey = userEditKey;
            this.#onValueChanged = onValueChanged;
            this.#inputCssClass = inputCssClass;
            this.#mapApiKey = mapApiKey;
        }
    }

    autoGenerateInputField (field, property) {

        switch (property.inputType) {

            case "HiddenInput":
                return this.renderHiddenInput(field);
                break;

            case "BooleanInput":
                return this.renderBooleanInput(field);
                break;

            case "TextInput":
                return this.renderTextInput(field);
                break;

            case "NumberInput":
                return this.renderNumberInput(field);
                break;

            case "TextAreaInput":
                return this.renderTextAreaInput(field, property.cols, property.rows);
                break;

            case "HtmlInput":
                return this.renderHtmlInput(field);
                break;

            case "MultiSelectInput":
                return this.renderMultiSelectInput(field, property.options);
                break;

            case "OptionPickerWithSearch":
                return this.renderOptionPickerWithSearch(field);
                break;

            case "DropDownInput":
                return this.renderDropDownInput(field, property.options);
                break;

            case "RadioButtonInput":
                return this.renderRadioButtonInput(field, property.options);
                break;

            case "LocationFields":
                return this.renderLocationFields(field, property, property.onAdd, property.onDelete);
                break;

            case "EntityRows":
                return this.renderEntityRows(field, property, property.onAdd, property.onDelete);
                break;

            default:
                console.error("input type '" + property.inputType + "' is unhandled.");
                break;
        }

    }

    getFormFieldByPropertyName (propertyName, fieldsArray)  {

        //  search the fields array for the property name and return the field if it exists

        var formFields = fieldsArray;

        var fieldNames = Object.keys(formFields);

        if (Array.isArray(fieldNames)) {

            for (var i = 0; i < fieldNames.length; i++) {

                if (fieldNames[i] == propertyName) {
                    var field = formFields[propertyName];
                    return field;
                }

            }

        }
        return undefined;

    }

    renderTextInput(field) {
        var divCss = this.getDivCss(field);

        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        
        return (
            <div class={divCss} >
                <FormFieldLabel field={field} />
                <TextInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    cssClass={this.#inputCssClass} />
                {
                    (!field.isValid()) && this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderNumberInput(field) {
        var divCss = this.getDivCss(field);

        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }

        return (
            <div class={divCss} >
                <FormFieldLabel field={field} />
                <NumberInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    cssClass={this.#inputCssClass} />
                {
                    (!field.isValid()) && this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderTextAreaInput(field, cols, rows) {
        var divCss = this.getDivCss(field);
        var elementCss = field.isValid() ? "form-field__textarea" : "form-field__textarea is-invalid";
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss} >
                <FormFieldLabel field={field} />
                <TextAreaInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    cssClass={elementCss}
                    cols={cols}
                    rows={rows} />
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderHtmlInput(field) {
        var divCss = this.getDivCss(field);
        var elementCss = field.isValid() ? "" : "is-invalid";
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss} >
                <FormFieldLabel field={field} />
                <HtmlInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    cssClass={elementCss} />
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderHyperlinkInput(field) {
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <p>
                <FormFieldLabel field={field} />
                <HyperlinkInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    cssClass={this.#inputCssClass} />
            </p>
        );
    }

    renderBooleanInput(field) {
        var divCss = this.getDivCss(field);
        var elementCss = field.isValid() ? "form-field__checkbox" : "form-field__checkbox is-invalid";
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss} >
                <FormFieldLabel field={field} />
                <div class="form-field__checkbox-item form-field form-field--checkbox">
                    <BooleanInput field={field}
                        editMode={editMode}
                        onValueChanged={this.#onValueChanged}
                        userEditKey={this.#userEditKey}
                        cssClass={elementCss} />
                    <span class="checkmark"></span>
                    <label class="form-field__label" for={field.inputElementId}></label>
                </div>
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderHiddenInput(field) {
        return (
            <HiddenInput field={field}
                onValueChanged={this.#onValueChanged}
                userEditKey={this.#userEditKey} />
        );
    }

    renderMultiSelectInput(field, treeViewItem) {
        var divCss = this.getDivCss(field);
        var elementCss = field.isValid() ? "form-field__dropdown" : "form-field__dropdown is-invalid";
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss} >
                <FormFieldLabel field={field} />
                <div class="multi-select-zone">
                    <MultiSelectInput field={field}
                        editMode={editMode}
                        onValueChanged={this.#onValueChanged}
                        userEditKey={this.#userEditKey}
                        treeViewItem={treeViewItem}
                        cssClass={elementCss} />
                </div>
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderDropDownInput(field, treeViewItem) {
        var divCss = this.getDivCss(field);
        var elementCss = field.isValid() ? "form-field__dropdown" : "form-field__dropdown is-invalid";
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss}>
                <FormFieldLabel field={field} />
                <DropDownInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    treeViewItem={treeViewItem}
                    cssClass={elementCss} />
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderRadioButtonInput(field, treeViewItem) {
        var divCss = field.isValid() ? "form-field form-field--radio" : "form-field form-field--radio is-invalid";
        var elementCss = "ktc-radio ktc-radio-list";
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss}>
                <FormFieldLabel field={field} />
                <RadioButtonInput field={field}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    userEditKey={this.#userEditKey}
                    treeViewItem={treeViewItem}
                    cssClass={elementCss} />
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderOptionPickerWithSearch(field) {
        var divCss = this.getDivCss(field);
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class={divCss}>
                <FormFieldLabel field={field} />
                <OptionPickerWithSearch field={field}
                    editMode={editMode}
                    searchMethod={field.property.searchMethod}
                    searchTermPlaceholder="start typing a service name.."
                    multiSelect="true"
                    cssClass={this.#inputCssClass}
                />
                {
                    this.renderValidationErrors(field.validationErrors)
                }
            </div>
        );
    }

    renderLocationFields(field, property, onAdd, onDelete) {
        var locations = field.value;
        var divCss = this.getDivCss(field);
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class="form-field" >
                <FormFieldLabel field={field} />
                {
                    locations?.map((location) => {
                        var fieldId = field.inputElementId + "_" + location.index;
                        return (
                            <LocationFields id={fieldId}
                                mapApiKey={this.#mapApiKey}
                                field={field}
                                property={property}
                                dataObject={location}
                                editMode={editMode}
                                onValueChanged={this.#onValueChanged}
                                inputHelper={this}
                                userEditKey={this.#userEditKey}
                                onDelete={onDelete}
                            />
                        )
                    })
                }

                <p><button type="button" class="button button--secondary" value={property.name} onClick={onAdd}>New item</button></p>
            </div>
        )

    }

    renderEntityRows(field, property, onAdd, onDelete) {
        var rows = field.value;
        var editMode = this.#editMode;
        if (field.property.allowEdit != undefined && field.property.allowEdit == false) {
            editMode = false;
        }
        return (
            <div class="form-field">
                <FormFieldLabel field={field} />
                <EntityRows
                    field={field}
                    property={property}
                    rows={rows}
                    editMode={editMode}
                    onValueChanged={this.#onValueChanged}
                    inputHelper={this}
                    userEditKey={this.#userEditKey}
                    onDelete={onDelete}
                    onAdd={onAdd}
                />
            </div>

        )

    }

    renderValidationErrors(validationErrors) {
        if (validationErrors != undefined && Array.isArray(validationErrors) && validationErrors.length > 0) {
            return (<div class="field-validation-error">
                {
                    validationErrors.map((validationError) => {
                        return (<div>{validationError.message}</div>)
                    })
                }
            </div>)
        }
    }

    hasValidationErrors = (fields) => {

        if (fields != undefined) {
            var fieldNames = Object.keys(fields);
            for (var i = 0; i < fieldNames.length; i++) {
                var field = fields[fieldNames[i]];
                if (Array.isArray(field.validationErrors) && field.validationErrors.length > 0) {
                    return true;
                }
            }
        }
        return false;

    }

    getDivCss(field) {
        var isValid = field.isValid();
        return isValid ? "form-field" : "form-field is-invalid";
    }
}
