import React from 'react';
import { Fragment } from 'preact';

export class WizardStep extends React.Component {

    render() {

        var step = this.props.step;

        var renderFields = (step.properties != undefined && step.properties.length > 0 && step.formFields != undefined);

        return (
            <div class={step.active ? "wizard-step active" : "wizard-step"}>
                <h2 class="wizard-step-heading">{step.name}</h2>
                <div class="box box--base" >
                    {

                        (renderFields) &&
                        step.properties.map((property) => {

                            var field = this.props.inputHelper.getFormFieldByPropertyName(property.name, step.formFields);

                            var renderField = ((step.stepType != undefined && step.stepType == "validation")) ? (field.validationErrors != undefined || field.validationErrors.length > 0) : (field.validationErrors == undefined || field.validationErrors.length == 0);

                            if (renderField) {
                                return (this.props.inputHelper.autoGenerateInputField(field, property))
                            }

                        })

                    }
                </div>
            </div>
        );
    }

}

