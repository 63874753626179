import React from 'react';
import { Fragment } from 'preact';
export class BooleanInput extends React.Component {

    onValueChanged = (event) => {
        this.props.field.setValue(event.target.checked, this.props.userEditKey);
        this.props.onValueChanged(this.props.field);
    }

    render() {
        var checked = this.props.field.value == true ? "checked" : "";
        return (
            <Fragment>
                {
                    (this.props.editMode == true) &&
                    <input type="checkbox"
                        id={this.props.field.inputElementId}
                        name={this.props.field.inputElementName}
                        checked={this.props.field.value}
                        value={this.props.field.value}
                        onChangeCapture={this.onValueChanged}
                        class="form-field__checkbox" />
                }
                {
                    (this.props.editMode == false) &&
                    <label>{this.props.field.value == true ? "Yes" : "No"}</label>
                }
            </Fragment>
        );
    }

}
