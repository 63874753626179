import React from 'react';
import { render } from 'preact';
import { BaseComponent } from 'project/general';
import AccountOrdersController from './account-orders.controller';

export class AccountOrdersComponent extends BaseComponent {
    static getNamespace() {
        return 'account-orders';
    }

    onInit() {
        render(<AccountOrdersController noOrderMessage={this.options.noOrderMessage}/>, this.element);
    }

    onDestroy() {
        render(null, this.element);
    }
}
