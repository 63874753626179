import { BaseComponent } from 'project/general';
import { getScrollTop, setScrollTop } from 'project/helpers';

export default class TabsComponent extends BaseComponent {
    constructor(...args) {
        super(...args);
        this.activeId = null;
        this.tabs = {};
    }

    static getNamespace() {
        return 'tabs';
    }

    static getRequiredRefs() {
        return ['links', 'contents'];
    }

    onInit() {
        if (this.refs.links && !Array.isArray(this.refs.links)) {
            this.refs.links = [this.refs.links];
        }

        if (this.refs.content && !Array.isArray(this.refs.links)) {
            this.refs.content = [this.refs.content];
        }

        this.refs.links.forEach((link) => {
            const foundContent = this.refs.contents.find((content) =>
                `#${content.id}` === link.hash) || null;

            if (!foundContent) {
                console.log(`Missed content ref for ${link.hash}`);
            }

            this.tabs[link.hash] = {
                id: link.hash,
                link,
                content: foundContent
            };
        });

        Object.keys(this.tabs).forEach((key) => {
            this.addListener(this.tabs[key].link, 'click', (e) => this.onLinkClick(e, key));
        });

        const hash = window.location.hash;

        if (hash) {
            const tab = this.tabs[hash];

            if (tab) {
                this.reset();
                this.activeId = tab.id;
                this.activate(this.activeId);
                return;
            }
        }

        const activeId = Object.keys(this.tabs).find((key) =>
            this.tabs[key].link.classList.contains('is-active'));

        if (activeId) {
            this.reset();
            this.activeId = activeId;
            this.activate(this.activeId);
        }
    }

    onLinkClick = (e, id) => {
        this.deactivate(this.activeId);
        this.activeId = id;
        this.activate(id);

        const scrollTop = getScrollTop();
        window.location.hash = this.tabs[id].link.hash;
        setScrollTop(scrollTop);
        e.preventDefault();
    };

    reset() {
        Object.keys(this.tabs).forEach((key) => {
            this.deactivate(key);
        });
    }

    activate(id) {
        const tab = this.tabs[id];

        if (!tab) return;

        if (tab.link) {
            tab.link.classList.add('is-active');
        }

        if (tab.content) {
            tab.content.classList.add('is-active');
        }
    }

    deactivate(id) {
        const tab = this.tabs[id];

        if (!tab) return;

        if (tab.link) {
            tab.link.classList.remove('is-active');
        }

        if (tab.content) {
            tab.content.classList.remove('is-active');
        }
    }
}
