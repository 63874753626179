import {Modal} from 'modal';
import {detectIE} from 'project/helpers';
import Cookies from 'js-cookie';
import {BaseComponent} from 'project/general';
import {gtmService} from 'project/services';
import {colourThemes} from './enums';


const FILTER_CLASS = 'filter';

export class DimmerSwitchComponent extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.cookieName = this.options.name;
        this.cookieOptions = this.options;
    }

    static getNamespace() {
        return 'dimmer-switch';
    }

    static getRequiredRefs() {
        return ['button'];
    }

    onInit() {
        this.addListener(this.refs.button, 'click', this.toggle);
    }

    applyTheme(themeName) {
        if (!themeName) throw 'Theme name must be specified';
        if (themeName === colourThemes.CALM) {
            document.documentElement.classList.add(FILTER_CLASS);
            this.refs.button.setAttribute('aria-checked', 'true');
        }
        if (themeName === colourThemes.VIVID) {
            document.documentElement.classList.remove('filter');
            this.refs.button.setAttribute('aria-checked', 'false');
        }
    }

    toggle = () => {
        if (detectIE()) {
            this.showModal();
            return;
        }

        if (this.refs.button.getAttribute('aria-checked') === 'true') {
            this.applyTheme(colourThemes.VIVID);
            this.setCookie(colourThemes.VIVID);
            this.sendToGtm('Dark > Light');
        } else {
            this.applyTheme(colourThemes.CALM);
            this.setCookie(colourThemes.CALM);
            this.sendToGtm('Light > Dark');
        }
    };

    showModal() {
        this.modal = Modal.create(
            `<div class="dimmer-switch__modal">
                <h3 class="dimmer-switch__modal-message heading-3">
                    Calm theme is not supported in this version of your browser, we recommend that you update your browser.
                </h3>
            </div>`
        );
        this.modal.open();

        const closeButton = this.modal.element.querySelector('[data-modal-close]');

        if (closeButton) {
            closeButton.addEventListener('click', () => {
                this.hideModal();
            });
        }
    }

    hideModal = () => {
        if (this.modal) {
            this.modal.destroy();
        }
    };

    setCookie(value) {
        Cookies.set(this.cookieName, `${value}`, this.cookieOptions);
    }

    getCookie() {
        return Cookies.get(this.cookieName, this.cookieOptions);
    }

    sendToGtm(label) {
        if (!label) return;

        gtmService.pushEvent('Dimmer Switch', 'Click', label);
    }
}
