import React from 'react';
import { render } from 'preact';

import { BaseComponent } from 'project/general';
import { BaseForm, formConstants } from 'shared/base-form';
import { notificationTypes } from 'project/general';
import { notificationService } from 'project/services';
import { FormField } from './input-fields/form-field';
import { Wizard } from './wizard/wizard';
import { NasFormFunctions } from './classes/nas-form-functions';

export default class NasFormComponent extends BaseComponent {

    initialDataObjectInstance = undefined; // the data object state as first loaded by the form.
    dataObjectInstance = undefined; // the data object to be managed via the form.
    validationErrors = [];
    formInstance = null;
    editMode = false;

    onDataObjectLoaded(dataObjectInstance) {
        this.dataObjectInstance = dataObjectInstance;
        this.initialDataObjectInstance = structuredClone(this.dataObjectInstance);
    }

    onInitForm() {

        //  Instantiate the form helper class, define submit behaviour
        this.formInstance = new BaseForm(this.element, {
            errorsSummary: document.getElementById("data-errors-summary"),
            onBeforeSubmit: () => { },
            onSuccessfulSubmit: (response) => {

                var submitServiceListingResponse = response.data.data;

                if (submitServiceListingResponse == undefined) {
                    var message = "";
                    if (response.data.errors != undefined) { message = response.data.errors[0]; }
                    else { message = "There was a problem completing the operation."; }
                    notificationService.push({
                        message: message,
                        type: notificationTypes.WARNING,
                    });
                }

                else {

                    if (submitServiceListingResponse.validationErrors != undefined
                        && submitServiceListingResponse.validationErrors.length > 0)
                    {
                        this.validationErrors = submitServiceListingResponse.validationErrors;
                        notificationService.push({
                            message: "There were validation errors",
                            type: notificationTypes.ERROR,
                        });
                        window.scrollTo(0, 0);
                        this.renderForm(this.editMode);
                    }
                    else {
                        this.validationErrors = [];
                        notificationService.push({
                            message: response.data.data.message,
                            type: notificationTypes.SUCCESS,
                        });
                        this.renderConfirmation();
                    }
                }


                
            },
            onFailedSubmit: (error) => {
                notificationService.push({
                    message: "There was an error",
                    type: notificationTypes.ERROR,
                });
            },
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<div class="field-validation-error" aria-live="assertive" aria-atomic="true"></div>',
                errorTemplate: '<div></div>',
            },
            enctype: formConstants.ENCTYPE_JSON
        });

    }

    onSubmitClick = () => {
        this.formInstance.onSubmit();
    }

    onCreateFormFields = (parentPropertyName, properties, dataObject) => {

        //  Creates form field info for the given properties and populates them with the relevant data
        var formFields = [];

        if (Array.isArray(properties)) {

            properties.map((property) => {

                var formField = new FormField(property);

                //  If it's a relational property set the input name and id based on the index of the related
                //  object
                if (parentPropertyName != undefined && parentPropertyName.length > 0) {
                    formField.inputElementName = parentPropertyName + "[" + dataObject.index + "][" + property.name + "]";
                    formField.inputElementId = parentPropertyName + "_" + dataObject.index + "_" + property.name;
                }

                //  If it's not a relational property set the input name and id to the property name
                else {
                    formField.inputElementName = property.name;
                    formField.inputElementId = property.name;
                }

                if (dataObject != undefined) {

                    this.nasFormFunctions = new NasFormFunctions();
                    formField.validationErrors = this.getValidationErrorsForField(property.parentPropertyName, property.name, dataObject.index);
            
                    if (dataObject[property.name] == undefined) {
                        //  populate field from default value
                        if (property.defaultValue != undefined) {
                            formField.value = property.defaultValue;
                        }
                    }
                    else {
                        //  populate field from data
                        formField.value = dataObject[property.name];
                    }

                }

                //  Add the form field to the output
                formFields[property.name] = formField;
            })
        }

        return formFields;
    }

    updateObject = (field) => {

        //  Find the object property using the input element name
        var propertyRef = this.parseinputElementName(field.inputElementName);

        //  Handle simple properties
        if (propertyRef.length == 1) {

            // update the object
            this.dataObjectInstance[field.name] = field.value;

        }

        //  Handle relational properties
        if (propertyRef.length == 3) {

            var property = propertyRef[0];
            var index = propertyRef[1];
            var childProperty = propertyRef[2];

            //  Does the property exist?
            if (this.dataObjectInstance[property] == undefined) {
                console.error("property '" + property + "' was undefined");
            }

            //  Is the property an array?
            else if (!Array.isArray(this.dataObjectInstance[property])) {
                console.error("property '" + property + "' is not an array");
            }

            //  Is the property array length less than the index in the field ref?
            else if (this.dataObjectInstance[property].length - 1 < index) {
                console.error("property '" + property + "' index out of range");
            }

            else {

                // update the child object
                this.dataObjectInstance[property][index][childProperty] = field.value;
            }

        }
    }

    onValuesChanged = (fields) => {

        if (Array.isArray(fields)) {

            fields.map((field) => {

                this.updateObject(field);

            })
        }
        else {

            this.updateObject(fields);
        }

        this.renderForm(this.editMode);

    }

    getValidationErrorsForField(parentPropertyName, propertyName, index) {

        var validationErrors = [];

        if (this.validationErrors != undefined && Array.isArray(this.validationErrors)) {

            for (var i = 0; i < this.validationErrors.length; i++) {

                var validationError = this.validationErrors[i];

                var valError_HasParentProperty = (validationError.parentPropertyName == undefined || validationError.parentPropertyName == "") ? false : true;
                var valError_ParentPropertyName = (validationError.parentPropertyName == undefined || validationError.parentPropertyName == "") ? "" : validationError.parentPropertyName;

                var property_HasParentProperty = (parentPropertyName == undefined || parentPropertyName == "") ? false : true;
                var property_ParentPropertyName = (parentPropertyName == undefined || parentPropertyName == "") ? "" : parentPropertyName;
   


                if (propertyName.toLowerCase() == valError_ParentPropertyName.toLowerCase()) {
                    if (index != undefined) {
                        if (validationError.index == index) {
                            validationErrors.push(validationError);
                        }
                    }
                    else {
                        validationErrors.push(validationError);
                    }
                }
                

                    var parentPropertyMatch = (
                        ( valError_HasParentProperty == property_HasParentProperty ) && 
                        (valError_ParentPropertyName.toLowerCase() == property_ParentPropertyName.toLowerCase() )
                    ) ? true : false;

                    var propertyMatch = (validationError.propertyName.toLowerCase() == propertyName.toLowerCase());

                    if (parentPropertyMatch && propertyMatch) {
                        if (index != undefined) {
                            if (validationError.index == index) {
                                validationErrors.push(validationError);
                            }
                        }
                        else {
                            validationErrors.push(validationError);
                        }

                    }
                




            }
        }

        return validationErrors;

    }

    parseinputElementName = (inputElementName) => {

        //  Example relational property:
        //      inputElementName = "contacts[0][firstName]"
        //      return {"contacts", 0, "firstName"}

        //  Example simple property:
        //      inputElementName = "title"
        //      return {"title"}

        //  replace any square brackets with pipes
        var fieldRef = inputElementName.replaceAll("[", "|");
        fieldRef = fieldRef.replaceAll("]", "|");

        //  replace any double pipes with single pipe
        fieldRef = fieldRef.replaceAll("||", "|");

        //  split the result to get an array
        var array = fieldRef.split("|", 3);

        //  convert any integers currently in string form to integer
        for (var i = 0; i < array.length; i++) {
            var value = array[i];
            var parsed = Number.parseInt(value);
            if (!isNaN(parsed)) {
                array[i] = parsed;
            }
        }

        return array;
    }

    onEdit = () => {
        this.editMode = true;
        this.renderForm(this.editMode);
    }

    onCancel = () => {
        if (confirm("Cancel all changes?")) {
            this.editMode = false;
            this.dataObjectInstance = structuredClone(this.initialDataObjectInstance);
            this.renderForm(this.editMode);
        }
    }

    renderForm(editMode) {

        if (this.formInstance == undefined) {
            this.onInitForm();
        }
        if (this.dataObjectInstance == undefined) {
            this.dataObjectInstance = [];
        }

        var dataObjectInstance = this.dataObjectInstance;
        this.steps = this.createWizardSteps();
        var mapApiKey = this.element.getAttribute("data-dc-nas-form-mapApiKey");
        render(
            <div>
                <Wizard
                    mapApiKey={mapApiKey}
                    onValueChanged={this.onValuesChanged}
                    steps={this.steps}
                    onSubmitClick={this.onSubmitClick}
                    inputCssClass="form-control form-field__input" />
            </div>
            ,
            this.refs.main_details_placeholder
        );

    }

    renderConfirmation() {

        render(
            <div style="margin:20px;">
                <h3>Thanks, that information has been submitted, you'll be notified by email when it's been processed.</h3>
            </div>
            ,
            this.refs.main_details_placeholder
        );

    }

    onDeleteChildRow = (property, dataObjectToRemove) => {


        var arrayValue = this.dataObjectInstance[property.name];

        if (Array.isArray(arrayValue) && arrayValue.length > 0) {

            if (confirm("Are you sure you want to delete from " + property.displayName + " ?") == true) {

                for (var i = 0; i < arrayValue.length; i++) {

                    var row = arrayValue[i];

                    if (row.index == dataObjectToRemove.index) {
                   
                        if (row.deleted != undefined) {

                            row.deleted = !row.deleted;
                        }
                        else {
                            row.deleted = true;
                        }

                    }

                }

                this.renderForm(this.editMode);

            }

        }

    }

    onAddChildRow = (property) => {

        var propertyName = undefined;

        var isEvent = property instanceof Event;
        if (isEvent) {
            propertyName = property.target.value;
        }
        else {
            propertyName = property.name;
        }

        var arrayValue = this.dataObjectInstance[propertyName];

        if (arrayValue == undefined) {
            arrayValue = [];
        }

        if (Array.isArray(arrayValue)) {

            //  initialise an empty object for the row
            var newRow = {};
            newRow.index = arrayValue.length;
            newRow.isCollapsed = false;
            arrayValue.push(newRow);
            this.dataObjectInstance[propertyName] = arrayValue;

            this.renderForm(this.editMode);

        }

    }


}
