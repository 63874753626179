import { createElement } from 'dom-helper';
import { notificationLabels } from './enums';

export class NotificationItem {
    constructor(notificationObject, options) {
        this.id = notificationObject.id;
        this.options = options;
        this.element = createElement(NotificationItem.template(notificationObject));
        this.addListeners();
        this.timer = setTimeout(() => {
            this.destroy();
            this.options.timeoutCallBack(this.id);
        }, this.options.timeout);
    }

    static template({ id, type, message }) {
        return `<div class="notifications__item notification-item notification-item--${type}" id="notification-${id}" role="alert" aria-live="assertive">
                    <div class="notification-item__inner">
                        <button class="notification-item__close-btn" type="button" data-notification-close title="close">
                            <svg class="icon notification-item__close-icon" width="15" height="15" focusable="false">
                                        <use xlink:href="#icon-cross"></use>
                                    </svg>
                        </button>
                        <div class="notification-item__label">
                            ${notificationLabels[type]}
                        </div>
                        <div class="notification-item__inner">
                            ${NotificationItem.messageTemplate(message)}
                        </div>
                    </div>
                </div>`;
    }

    static messageTemplate(message) {
        if (Array.isArray(message)) {
            if (message.length > 1) {
                return `<ul>
                            ${message.map((item) => '<li>' + item + '</li>').join('')}
                        </ul>`;
            }
            return message[0];
        }
        return message;
    }

    addListeners() {
        this.closeButton = this.element.querySelector('[data-notification-close]');
        this.closeButton.addEventListener('click', this.onCloseClick);
    }

    removeListeners() {
        this.closeButton.removeEventListener('click', this.onCloseClick);
    }

    onCloseClick = () => {
        this.options.closeCallBack(this.id);
    };

    getElement() {
        return this.element;
    }

    destroy() {
        window.clearTimeout(this.timer);
        this.removeListeners();
    }
}
