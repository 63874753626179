import React from 'react';
import { SPINNER_SHOW_CLASS } from 'shared/spinner';
import { EventOptionItemQuantity } from './event-option-item-quantity';

export class EventOptionListQuantity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quantityState: {},
            totalQuantity: 0
        };
    }

    componentDidMount() {
        this.setState({
            quantityState: this.getQuantityStateByProps(this.props)
        });
    }

    getQuantityStateByProps(props) {
        const quantityState = {};
        if (Array.isArray(props.expandedOptions)) {
            props.expandedOptions.forEach((option) => {
                quantityState[option.clientId] = 0;
            });
        }

        return quantityState;
    }

    componentWillReceiveProps(nextProps) {
        const quantityState = this.getQuantityStateByProps(nextProps);
        this.setState({
            quantityState
        });
    }

    onQuantityChange = (newQuantity, clientId) => {
        const newQuantityState = { ...this.state.quantityState };
        newQuantityState[clientId] = newQuantity;
        const totalQuantity = Object.values(newQuantityState).reduce((a, b) => a + b, 0);
        this.setState({
            quantityState: newQuantityState,
            totalQuantity
        });
    };

    render() {
        const {
            expandedOptions, pleaseSelectOptionMessage, quantityExcessMessage, totalQuantityMaxValue
        } = this.props;
        return (
            <>


                    <div className="event-expanded__list">
                        {expandedOptions.length > 0 && expandedOptions.map((item) => {
                            return (
                                <EventOptionItemQuantity {...item}
                                    quantity={this.state.quantityState[item.clientId]}
                                    onQuantityChange={this.onQuantityChange}
                                    key={item.clientId}/>
                            );
                        })}
                    </div>


                    <div className="form-field">
                        {/* The following input is for validation.  */}
                        <input type="number"
                            className="visually-hidden"
                            data-parsley-max-message={quantityExcessMessage}
                            data-parsley-min-message={pleaseSelectOptionMessage}
                            data-parsley-min={1}
                            data-parsley-max={totalQuantityMaxValue}
                            value={this.state.totalQuantity} required/>
                    </div>


            </>
        );
    }
}
