import './scss/index.scss';
import { dcFactory } from 'dc';
import ToggleMenuComponent from './js/toggle-menu.component';
import SideNavigationComponent from './js/side-navigation.component';
import GuidanceSideNavigationComponent from './js/guidance-side-navigation-mobile.component';
import CollapseMenuComponent from './js/collapse-menu.component';

dcFactory.register(ToggleMenuComponent);
dcFactory.register(SideNavigationComponent);
dcFactory.register(GuidanceSideNavigationComponent);
dcFactory.register(CollapseMenuComponent);
