import React from 'react';
import { render } from 'preact';
import { BaseComponent } from 'project/general';
import AccountOrdersController from './account-events.controller';

export default class AccountEventsComponent extends BaseComponent {
    static getNamespace() {
        return 'account-events';
    }

    onInit() {
        render(<AccountOrdersController options={this.options} />, this.element);
    }

    onDestroy() {
        render(null, this.element);
    }
}
