import './scss/index.scss';
import { dcFactory } from 'dc';
import CookieSettingsComponent from './js/cookie-settings.component';
import cookieSettingsModal from './js/cookie-settings-modal';

dcFactory.register(CookieSettingsComponent);

export {
    cookieSettingsModal,
};
