import { BaseComponent } from 'project/general';

class ProviderPaginationComponent extends BaseComponent {
    static getNamespace() {
        return 'provider-pagination';
    }

    onInit() {
        //Pagination
        var pageSize = 4;
        var incremSlide = 5;
        var startPage = 0;
        var numberPage = 0;

        var pageCount = $(".service-card").length / pageSize;
        var totalSlidepPage = Math.floor(pageCount / incremSlide);

        for (var i = 0; i < pageCount; i++) {
            $("#paging").append('<li><a>' + (i + 1) + '</a></li>');
            if (i > pageSize) {
                $("#paging li").eq(i).hide();
            }
        }

        var prev = $("<li/>").addClass("prev").html("Prev").click(function () {
            startPage -= 5;
            incremSlide -= 5;
            numberPage--;
            slide();
        });

        prev.hide();

        var next = $("<li/>").addClass("next").html("Next").click(function () {
            startPage += 5;
            incremSlide += 5;
            numberPage++;
            slide();
        });

        $("#paging").prepend(prev).append(next);

        $("#paging li").first().find("a").addClass("current");

        var slide = function (sens) {
            $("#paging li").hide();

            for (var t = startPage; t < incremSlide; t++) {
                $("#paging li").eq(t + 1).show();
            }
            if (startPage == 0) {
                next.show();
                prev.hide();
            } else if (numberPage == totalSlidepPage) {
                next.hide();
                prev.show();
            } else {
                next.show();
                prev.show();
            }
        }

        var showPage = function (page) {
            $(".service-card").hide();
            $(".service-card").each(function (n) {
                if (n >= pageSize * (page - 1) && n < pageSize * page)
                    $(this).show();
            });
        }

        showPage(1);
        $("#paging li a").eq(0).addClass("current");

        $("#paging li a").click(function () {
            $("#paging li a").removeClass("current");
            $(this).addClass("current");
            showPage(parseInt($(this).text()));
        });
    }


}

export default ProviderPaginationComponent;
