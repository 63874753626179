document.addEventListener("DOMContentLoaded", function (event) {

    var reciteButton = document.getElementById("enableRecite");
    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    var pageHeader = document.querySelector(".page-header");
    var navWrapper = document.querySelector(".nav-wrapper");

    //Using document.body.contains.
    if (document.body.contains(reciteButton)) {
        reciteButton.addEventListener("click", function () {

            viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            if (viewportWidth < 854) {
                pageHeader.classList.remove("menu-active");
                navWrapper.classList.remove("show-menu");
            }

            loadService();

        });
    }

   
});
