import { BaseComponent } from 'project/general';
import './jquery.ellipsis.min.js';

class TextTruncationComponent extends BaseComponent {

    static getNamespace() {
        return 'text-truncation';
    }

    onInit() {
        $(this.element).ellipsis({
            lines: 3, // default: 'auto',
            responsive: true
        });
    }

}

export default TextTruncationComponent;
