import React from 'react';
import { SPINNER_SHOW_CLASS } from 'shared/spinner';
import { EventOptionListQuantity } from './event-option-list-quantity';
import { EventOptionListRadio } from './event-option-list-radio';


export class EventOptionListRoot extends React.Component {
    render() {
        const {
            title, noDataMessage, expandedOptions, isLoading, allowGroupBookings
        } = this.props;
        return (

            <>
                <h4 className='event-expanded__title'>{title}</h4>
                {!isLoading &&
                <>
                    {allowGroupBookings && <EventOptionListQuantity {...this.props} /> }
                    {!allowGroupBookings && <EventOptionListRadio {...this.props} /> }
                </>
                }
                {expandedOptions && expandedOptions.length === 0 && (
                    <div>{noDataMessage}</div>
                )}


                {isLoading &&
                <div className={`event-expanded__spin spinner-container ${isLoading ? SPINNER_SHOW_CLASS : ''}`}>
                    <div className="spinner-container__spinner spinner spinner--purple">Loading</div>
                </div>
                }

            </>
        );
    }
}
