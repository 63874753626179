import React from 'react';

export class MultiSelectBase extends React.Component {

    onItemClick = (event) => {

        var checked = event.target.checked;

        var treeViewItem = this.getTreeViewItemById(event.target.id);

        if (treeViewItem == undefined && event.target.id != undefined) {
            // parse the field id to pluck the selected option id from the field id
            //      e.g. take 'mobile' from 'preferredMethodsOfContact#mobile'
            var indexOfHash = event.target.id.indexOf("#");
            var optionId = event.target.id.substring(indexOfHash + 1);
            treeViewItem = this.getTreeViewItemById(optionId);
        }

        if (treeViewItem != undefined) {

            if (checked) {
                this.props.field.pushArrayValue(treeViewItem, this.props.userEditKey);
            }
            else {
                this.props.field.removeArrayValue(treeViewItem.id, this.props.userEditKey);
            }
            this.props.onValueChanged(this.props.field);
        }

    }

    getTreeViewItemById = (id) => {

        var items = this.props.treeViewItem.children;

        for (var i = 0; i < items.length; i++) {

            if (items[i].id == id) {

                return this.props.treeViewItem.children[i];

            }
            else if (items[i].children != undefined && items[i].children.length > 0) {
                var childItems = items[i].children;
                for (var x = 0; x < childItems.length; x++) {
                    if (childItems[x].id == id) {
                        return childItems[x];
                    }
                }
            }
        }
        return undefined;
    }

    createCheckboxId(option) {
        if (option != undefined) {
            if (option.id != undefined) {
                return this.props.field.inputElementName + "#" + option.id;
            }
            else {
                return this.props.field.inputElementName + "#" + option;
            }
        }    
    }

}
