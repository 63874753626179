$(window).on("load resize", function (e) {
    var headerBar = document.querySelector('.inner-right');
    var dimmerSwitch = document.querySelector('.utility-navigation__dimmer-switch');
    var dimmerHolder = document.querySelector('.utility-navigation__dimmer-holder');
    if (document.body.clientWidth < 1120 && document.body.clientWidth > 330) {
        headerBar.insertAdjacentElement('afterbegin', dimmerSwitch);
    }

    else {
        dimmerHolder.appendChild(dimmerSwitch);
    }

});
