import React from 'react';
import { createPortal } from 'preact/compat';
import PropTypes from 'prop-types';
import ModalService from './modal-service';

class ReactModal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        document.body.appendChild(this.el);
        const options = {
            ...this.props.options,
            onClose: this.props.onClose,
        };

        this.modal = ModalService.create(this.el, options);

        if (this.props.isOpen) {
            this.modal.open();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            if (this.props.isOpen) {
                this.modal.open();
            } else {
                this.modal.close();
            }
        }
    }

    componentWillUnmount() {
        this.modal.destroy();
    }

    render() {
        return createPortal(
            this.props.children,
            this.el,
        );
    }
}

ReactModal.propTypes = {
    options: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default ReactModal;
