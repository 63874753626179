import { BaseComponent } from 'project/general';

class ImageGalleryComponent extends BaseComponent {

    static getNamespace() {
        return 'image-gallery';
    }

    onInit() {
        var owlGallery = $(this.element);
        var owlID = owlGallery.attr("id");
        owlGallery.owlCarousel({
            margin: 0,
            nav: false,
            dots: false,
            autoHeight: true,
            lazyLoad: true,
            smartSpeed: 1000,
            responsive: {
                0: {
                    items: 1,
                },
            },
            onInitialized: function (event) {
                $(owlGallery).find('.owl-item').attr('aria-selected', 'false');
                $(owlGallery).find('.owl-item.active').attr('aria-selected', 'true'); // let screen readers know an item is active
                // apply meta info to next and previous buttons and make them focusable
                $(owlGallery).parent().find('.owl-buttons__button--owl-next').attr('role', 'button').attr('title', 'Previous');
                $(owlGallery).parent().find('.owl-buttons__button--owl-next').attr('title', 'Next');
                $(owlGallery).attr('tabindex', '0');
                $('.owl-buttons__button--owl-next, .owl-buttons__button--owl-prev').attr('tabindex', '0');

                // listen for keyboard input
                $(document).on('keydown', function (e) {

                    var $focusedElement = $(document.activeElement),
                        singleOwl = $(owlGallery).data('owlCarousel'),
                        type = e.which == 39 ? 'next' : null,
                        type = e.which == 37 ? 'prev' : type,
                        type = e.which == 13 ? 'enter' : type;

                    // if the carousel is focused, use left and right arrow keys to navigate
                    if ($focusedElement.attr('id') === owlID) {
                        if (type == 'next') {
                            owlGallery.trigger('next.owl.carousel');
                        } else if (type == 'prev') {
                            owlGallery.trigger('prev.owl.carousel');
                        }

                        // if the prev and next buttons are focused, catch "Enter" and navigate in the right direction
                    } else if (type == 'enter') {
                        if ($focusedElement.attr('class') === owlID + '-next') {
                            owlGallery.trigger('next.owl.carousel');
                        } else if ($focusedElement.attr('class') === owlID + '-prev' + ' owl-buttons__button owl-buttons__button--owl-prev') {
                            owlGallery.trigger('prev.owl.carousel');
                        }
                    }
                });
            }
        });


        // Button actions for next & Prev
        $('.' + owlID + ' .owl-buttons__button--owl-next').click(function () {
            owlGallery.trigger('next.owl.carousel');
        });
        $('.' + owlID + ' .owl-buttons__button--owl-prev').click(function () {
            owlGallery.trigger('prev.owl.carousel');
        });
    }

}

export default ImageGalleryComponent;
