import { BaseForm } from 'shared/base-form';
import { BaseComponent } from 'project/general';
import { baseApi } from 'project/api';

const ENCTYPE_JSON = 'json';

export class SignUpComponent extends BaseComponent {
    constructor(...args) {
        super(...args);

        this.state = {
            count: 2,
            tab: 0,
            step: 0,
            progress: 0,
        };
    }

    static getNamespace() {
        return 'sign-up';
    }

    static getRequiredRefs() {
        return [
            'progress',
            'count',
            'tabs',
            'form',
            'prevBtn',
            'nextBtn',
            'submitBtn'
        ];
    }

    onInit() {
        this.state.step = 100 / (this.refs.tabs.length + 1);
        this.state.progress = this.state.step;

        this.addListener(this.refs.prevBtn, 'click', this.prevTab);
        this.addListener(this.refs.nextBtn, 'click', this.nextTab);

        this.showTab(this.state.tab);

        document.addEventListener('keypress', function (e) {
            if (e.keyCode === 13 || e.which === 13) {
                e.preventDefault();
                return false;
            }

        });



        this.formInstance = new BaseForm(this.refs.form, {

            submitElement: this.element.querySelector('button[type="submit"]'),
            errorsSummary: this.element.querySelector('[data-errors-summary]'),
            onSuccessfulSubmit: (res) => {
                if (res.data.redirectUrl) {
                    window.location.href = res.data.redirectUrl;
                }
                //TODO: Display info block {res.data.message}
            },
            onFailedSubmit: (error) => {
                //TODO: Nothing happens
                return `<div class="alert alert-danger" role="alert"><div>${error}</div></div>`;
            },
            errorsSummaryTemplate: (errors) => {
                //TODO: Nothing happens
                return `<div class="alert alert-danger" role="alert">${errors.map((error) => `<div>${error}</div>`).join()}</div>`;
            },
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<div class="field-validation-error" aria-live="assertive" aria-atomic="true"></div>',
                errorTemplate: '<div></div>',
            },
            enctype: ENCTYPE_JSON,
            axiosInstance: baseApi,

        });
    }

    onDestroy() {
        this.formInstance.destroy();
    }

    showTab(tabIndex) {
        const tabs = this.refs.tabs;

        if (tabIndex >= tabs.length || tabIndex < 0) return;

        tabs.forEach((tab) => tab.classList.remove('is-current'));

        tabs[tabIndex].classList.add('is-current');

        this.updateStep();
        this.showOrHiddenControls();
    }

    prevTab = () => {
        if (this.state.tab <= 0) {
            this.state.tab = 0;
            return;
        }

        this.state.tab--;
        this.state.count--;
        this.state.progress -= this.state.step;

        this.showTab(this.state.tab);
    };

    nextTab = () => {
        if (this.state.tab >= this.refs.tabs.length - 1) {
            this.state.tab = this.refs.tabs.length - 1;
            return;
        }

        if (this.validate()) {
            this.state.tab++;
            this.state.count++;
            this.state.progress += this.state.step;

            this.showTab(this.state.tab);
        }
    };

    validate() {
        return this.formInstance.getParsleyForm().validate();
    }

    updateStep() {
        this.refs.progress.style.width = `${this.state.progress}%`;
        this.refs.count.innerText = `0${this.state.count}`;
    }

    showOrHiddenControls() {
        if (this.state.tab === 0) {
            this.refs.prevBtn.classList.add('is-hidden');
        } else {
            this.refs.prevBtn.classList.remove('is-hidden');
        }

        if (this.state.tab === this.refs.tabs.length - 1) {
            this.refs.nextBtn.classList.add('is-hidden');
            this.refs.submitBtn.classList.remove('is-hidden');
        } else {
            this.refs.nextBtn.classList.remove('is-hidden');
            this.refs.submitBtn.classList.add('is-hidden');
        }
    }

}
