import React from 'react';
export class HiddenInput extends React.Component {

    onValueChanged = (event) => {
        this.props.field.setValue(event.target.value, this.props.userEditKey);
        this.props.onValueChanged(this.props.field);
    }

    render() {
        return (
            <input type="hidden"
                id={this.props.field.inputElementId}
                name={this.props.field.inputElementName}
                value={this.props.field.value}
                class={this.props.cssClass} />
        );
    }

}
