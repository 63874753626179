import React from 'react';
import qs from 'qs';
import { apiNames } from 'project/general';
import { getApiUrlByName } from 'project/helpers';
import { baseApi } from 'project/api';
import { AccountEventsLayout } from './account-events-layout';

const PROFILE_EVENTS = getApiUrlByName(apiNames.PROFILE_EVENTS);

export default class AccountEventsController extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            products: null,
            pagination: {
                current: 1,
            },
            pager: null,
        };
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders = () => {
        const queryString = qs.stringify({
            page: this.state.pagination.current,
        });

        const url = PROFILE_EVENTS + '?' + queryString;

        baseApi
            .get(url)
            .then((res) => {
                if (!res.data.success) return;

                const { data } = res.data;

                this.setState({
                    count: data.count,
                    products: data.items,
                    pager: data.pager,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    selectPage = (pageIndex) => {
        this.setState({
            pagination: {
                current: pageIndex,
            }
        }, this.fetchOrders);
    };

    render() {
        return (
            <AccountEventsLayout
                count={this.state.count}
                products={this.state.products}
                pager={this.state.pager}
                selectPage={this.selectPage}
                options={this.props.options}
            />
        );
    }
}
