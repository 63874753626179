import { baseApi } from 'project/api';
import { BaseForm, formConstants } from 'shared/base-form';
import { BaseComponent, notificationTypes } from 'project/general';
import { notificationService } from 'project/services';

export class AccountDetailsFormComponent extends BaseComponent {
    static getNamespace() {
        return 'account-details-form';
    }

    onInit() {
        this.formInstance = new BaseForm(this.element, {
            submitElement: this.element.querySelector('button[type="submit"]'),
            errorsSummary: this.element.querySelector('[data-errors-summary]'),
            onSuccessfulSubmit: (res) => {
                if (res.data.success) {
                    notificationService.push({
                        message: res.data.message,
                        type: notificationTypes.SUCCESS,
                    });
                }
            },
            onFailedSubmit: (error) => {
                console.error(error);
            },
            errorsSummaryTemplate: (errors) => {
                return `<div class="alert alert-danger" role="alert">${errors.map((error) => `<div>${error}</div>`).join()}</div>`;
            },
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<div class="field-validation-error" aria-live="assertive" aria-atomic="true"></div>',
                errorTemplate: '<div></div>',
            },
            enctype: formConstants.ENCTYPE_JSON,
            axiosInstance: baseApi,
        });
    }

    onDestroy() {
        this.formInstance.destroy();
    }
}
