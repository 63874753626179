import React from 'react';
import { Pagination } from 'shared/pagination';
import { AccountEventsList } from './account-events-list';

export const AccountEventsLayout = (props) => {
    const renderCount = (count) => {
        return count !== 1 ?
            `${count} events` :
            `${count} event`;
    };
    return (
        <>
            <div className="account-events__wrapper">
                <h2 className="account-events__title heading-2">My events</h2>
                {props.count > 0 &&
                    <span className="account-events__count">{renderCount(props.count)}</span>
                }
            </div>
            {props.options.description &&
                <p className="account__text-partition paragraph-5">
                    {props.options.description}
                </p>
            }
            {props.count === 0 &&
                <h3 className="account-events__no-events heading-3">{props.options.noEventMessage}</h3>
            }
            <div className="account-events__list">
                <AccountEventsList products={props.products} options={props.options} />
            </div>
            <div className="account-events__pagination">
                <Pagination
                    pager={props.pager}
                    onSelectPage={props.selectPage}
                />
            </div>
        </>
    );
};
