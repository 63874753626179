import { Modal } from 'modal';

class MembershipController {
    constructor() {
        this.modal = null;
        this.modalToDestroy = null;
    }

    showModal = (html) => {
        if (!html) return;


        this.modalToDestroy = this.modal;
        this.modal = Modal.create(html);
        this.modal.open();
        this.initCustomCloseBtn();

        if (this.modalToDestroy !== null) {
            this.modalToDestroy.destroy();
        }
    };


    initCustomCloseBtn = () => {
        const closeButton = this.modal.element.querySelector('[data-close-modal]');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                this.modal.close();
            });
        }
    }
}

export const membershipController = new MembershipController();
