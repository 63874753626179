
export class FormField {

    name;
    displayName;
    value;
    inputElementId;
    inputElementName;
    property;
    validationErrors = [];
    #originalValue;
    userEdits = [];

    constructor(property) {

        this.property = property;

        if (this.property.value != this.value) {
            this.value = initialValue;
        }
        this.#originalValue = structuredClone(this.property.value);
        this.name = this.property.name;
        this.displayName = this.property.displayName;
    }

    isDirty() {
        var keys = Object.keys(this.userEdits);
        return keys.length > 0;
    }

    isNullOrEmpty() {
        return (this.value == undefined || this.value == "");
    }

    isOptionSelected(option) {

        if (this.value != undefined && option != undefined) {

            if (Array.isArray(this.value)) {
                var optionsInField = this.value;
                var isSelected = this.isOptionSelectedRecursive(option, optionsInField);
                return isSelected;
            }
            else {
                if (this.value.id != undefined) {
                    return this.value.id == option.id;
                }
                else {
                    return this.value == option.id;
                }
            }

        }

        return false;
    }

    isOptionSelectedRecursive(optionToDetect, optionArray) {

        var isSelected = false;

        if (optionToDetect != undefined && Array.isArray(optionArray)) {

            for (var i = 0; i < optionArray.length; i++) {

                var optionValue = optionArray[i];

                if (!isSelected && optionValue.id != undefined) {
                    isSelected = (optionValue.id == optionToDetect.id);
                }
                else if (!isSelected) {
                    isSelected = (optionValue == optionToDetect.id);
                }
            }
        }

        return isSelected;
    }


    setValue(userInput, userEditKey) {
        if (userEditKey == undefined) {
            console.error("setValue must be invoked with a user edit key.");
        }
        if (userInput != this.value) {
            this.userEdits[userEditKey] = structuredClone(userInput);
            this.value = userInput;
        }
    }

    isValid() {
        return this.validationErrors.length == 0;
    }

    pushArrayValue(userInput, userEditKey) {
        if (userEditKey == undefined) {
            console.error("setValue must be invoked with a user edit key.");
        }

        if (this.value == undefined) {
            this.value = [];
        }

        if (userInput != this.value) {
            this.value.push(userInput);
            this.userEdits[userEditKey] = this.value;
        }
    }

    removeArrayValue(id, userEditKey) {
        if (userEditKey == undefined) {
            console.error("setValue must be invoked with a user edit key.");
        }

        for (var i = 0; i < this.value.length; i++) {

            var thisValue = this.value[i];

            if (thisValue.id != undefined) {
                if (thisValue.id == id) {
                    this.value.splice(i, 1);
                }
            }
            else {
                if (thisValue == id) {
                    this.value.splice(i, 1);
                }
            }

        }
        this.userEdits[userEditKey] = this.value;
        
    }

    // safe way to get the original value while protecting it from pollution
    getOriginalValue() {
        return structuredClone(this.#originalValue);
    }

    // safe way to reset the field value back to the original value while protecting the original value from pollution
    revertToOriginalValue() {
        this.value = this.getOriginalValue();
        this.userEdits = [];
    }

    undoLatestEdit() {
        
        var keys = Object.keys(this.userEdits);

        if (keys.length == 1) {
            this.revertToOriginalValue();
        }

        else if (keys.length > 1) {
            var currentEditIndex = keys.length - 1;
            var previousEditIndex = keys.length - 2;

            if (previousEditIndex < 0) {
                console.error("Can't undo edit.");
            }
            else {
                var previousKey = keys[previousEditIndex];
                this.value = structuredClone(this.userEdits[previousKey]);
                var currentKey = keys[currentEditIndex];
                delete this.userEdits[currentKey];
            }
        }

    }

    getCurrentUserEditKey() {
        var keys = Object.keys(this.userEdits);
        return keys[keys.length - 1];
    }

    getCurrentValue() {
        var keys = Object.keys(this.userEdits);
        if (keys.length > 0) {
            return this.userEdits[keys[keys.length - 1]];
        }
        return this.value;
    }

    clearPreviousEdits() {
        var keys = Object.keys(this.userEdits);
        if (keys.length > 1) {
            for (var i = 0; i < keys.length - 1; i++) {
                var key = keys[i];
                delete this.userEdits[key];
            }
        }
    }
}
