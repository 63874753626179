import React from 'react';
import { Fragment } from 'preact';
import { NasFormFunctions } from '../classes/nas-form-functions';

export class DropDownInput extends React.Component {

    #nasFormFunctions = undefined;
    nasFormFunctions = () => {
        if (this.#nasFormFunctions == undefined) {
            this.#nasFormFunctions = new NasFormFunctions();
        }
        return this.#nasFormFunctions;
    }

    onValueChanged = (event) => {
        this.props.field.setValue(event.target.value, this.props.userEditKey);
        this.nasFormFunctions().markOptionSelected(this.props.field.value, this.props.field.property.options, true);
        this.props.onValueChanged(this.props.field);
    }

    render() {
        var options = this.props.treeViewItem.children;

        return (

            <Fragment>
                <select id={this.props.field.inputElementId} name={this.props.field.inputElementName} onChangeCapture={this.onValueChanged} class={ this.props.cssClass}>
                    <option text="select an option.." value="" />
                    {options.map((option) => {

                        var isOptionSelected = this.props.field.isOptionSelected(option);
                        return (
                            <option text={option.displayName}
                                value={option.id}
                                selected={isOptionSelected ? "selected" : ""}
                                aria-selected={isOptionSelected ? "true" : "false"}
                            />
                        )

                    })}
                </select>
            </Fragment>
        );
    }
}
