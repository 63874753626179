import { BaseComponent } from 'project/general';

class SearchWidgetComponent extends BaseComponent {


    static getNamespace() {
        return 'search-widget';
    }

    onInit() {
        var parentSection = this.element.closest('.generic-section');
        parentSection.style.zIndex = "999";
    }
}

export default SearchWidgetComponent;
