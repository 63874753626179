//import "preact/debug";

import { NasFormComponent } from 'shared/nas-form';
import { ServiceEditorService } from 'project/services';

// Note : this is not a React Component - it creates them
export default class ServiceAccessRequestForm extends NasFormComponent {

    serviceCategories = null;
    steps = null;

    static getNamespace() {
        return 'service-access-request-form';
    }

    static getRequiredRefs() {
        return ['main_details_placeholder'];
    }

    onInit() {
        this.editMode = false;
        this.renderForm(this.editMode);
    }

    createWizardSteps = () => {

        var steps = [];

        var step = {
            name: "Service access request", properties:
                [
                    {
                        name: "services",
                        displayName: "Other services you want access to",
                        inputType: "OptionPickerWithSearch",
                        searchMethod: ServiceEditorService.findNonEditableServicesByName,
                        selectedItemsLabel: "Selected services"
                    }
                ]
        };
        step.formFields = this.onCreateFormFields(undefined, step.properties, this.dataObjectInstance);

        //  Automatically add the selected service to the services field
        var selectedService = {
            id: this.options.nodeGuid,
            displayName: this.options.serviceName
        };
        step.formFields.services.pushArrayValue(selectedService, this.options.nodeGuid);

        //  add the step to the form
        steps.push(step);

        return steps;
    }

  
}
