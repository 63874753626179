import { BaseComponent } from 'project/general';

class SidebarFilterComponent extends BaseComponent {

    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    static getNamespace() {
        return 'sidebar-filter';
    }

    onInit() {
        this.refs.button.addEventListener('click', this.onClick);
    }

    onClick = () => {
        if (this.refs.submenu.classList.contains('active')) {
            this.refs.submenu.classList.remove('active');
        } else {
            this.refs.submenu.classList.add('active');
        }
        if (this.refs.icon.classList.contains('active')) {
            this.refs.icon.classList.remove('active');
        } else {
            this.refs.icon.classList.add('active');
        }
    };

}

export default SidebarFilterComponent;

(function ($) {
    var CheckboxDropdown = function (el) {
        var _this = this;
        this.isOpen = false;
        this.$el = $(el);
        this.$label = this.$el.find('.sidebar-filter-label');
        this.$labelNumber = this.$el.find('.sidebar-filter-number');
        this.$inputs = this.$el.find('[type="checkbox"]');


        this.onCheckBox();

        this.$inputs.on('change', function (e) {
            _this.onCheckBox();
        });
    };

    CheckboxDropdown.prototype.onCheckBox = function () {
        this.updateStatus();
    };

    CheckboxDropdown.prototype.updateStatus = function () {
        var checked = this.$el.find(':checked');

        var checkedLength = checked.length;

        if (checkedLength <= 0) {
            this.$label.removeClass('active');
            this.$labelNumber.removeClass('active');
            this.$label.html();
            this.$labelNumber.html("");
        }
        else {
            this.$labelNumber.html("");
            this.$label.addClass('active');
            this.$labelNumber.addClass('active');
            this.$labelNumber.html(checkedLength)
        }
    };

    var checkboxesDropdowns = document.querySelectorAll('[data-control="sidebar-select"]');
    for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
        new CheckboxDropdown(checkboxesDropdowns[i]);
    }
})(jQuery);
