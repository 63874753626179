import { CookieService } from 'project/services';
import { BaseComponent } from 'project/general';
import { cookieSettingsModal } from 'components/cookie-settings';

export default class CookieBarComponent extends BaseComponent {
    static getNamespace() {
        return 'cookie-bar';
    }

    static getRequiredRefs() {
        return [
            'closeButton',
            'settingsButton',
            'allowButton'
        ];
    }

    onInit() {

        this.addListener(this.refs.closeButton, 'click', this._onClickCloseBtn);
        this.addListener(this.refs.settingsButton, 'click', this._onClickSettingsBtn);
        this.addListener(this.refs.allowButton, 'click', this._onClickAllowBtn);
        CookieService.subscribeOnUpdateSettings(this._closeCookieBar);
        document.addEventListener('default-cookie-level-set', this._showCookieBar);

    }

    onDestroy() {
        clearTimeout(this.timeoutId);
        cookieSettingsModal.destroy();
    }

    _onClickCloseBtn = () => {
        this._closeCookieBar();
    };

    _onClickSettingsBtn = () => {
        cookieSettingsModal.open();
    };

    _onClickAllowBtn = () => {
        CookieService.saveCookieLevel(this.options.acceptedLevel);
    };

    _showCookieBar = () => {
        this.element.removeAttribute('hidden');
        this.timeoutId = setTimeout(() => {
            this.element.classList.add('is-visible');
        }, 1000);
    };

    _closeCookieBar = (level) => {
        this.element.classList.remove('is-visible');
        this.timeoutId = setTimeout(() => {
            this.element.setAttribute('hidden', '');
        }, 1000);
    };

}
