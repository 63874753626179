import { ArrayHelper } from 'foundation/helpers';
import { cookieSettingsModal } from 'components/cookie-settings';

const COOKIE_SETTINGS_ID = 'cookie-settings';

class CookieButtonsInitiator {
    constructor() {
        this.cookieButtons = [...document.querySelectorAll(`a[href="#${COOKIE_SETTINGS_ID}"]`)];

        this._addListener();
    }

    _addListener() {
        if (ArrayHelper.isEmpty(this.cookieButtons)) return;

        this.cookieButtons.forEach((button) => {
            button.addEventListener('click', this._onClickButton);
        });
    }

    _onClickButton = (e) => {
        e.preventDefault();

        cookieSettingsModal.open();
    };
}

export default new CookieButtonsInitiator();
