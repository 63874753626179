import { dcFactory } from 'dc';
import { apiNames, BaseComponent } from 'project/general';
import { getApiUrlByName } from 'project/helpers';
import { baseApi } from 'project/api';

const CURRENT_USER_NAVIGATION = getApiUrlByName(apiNames.CURRENT_USER_NAVIGATION);
const UTILITY_NAVIGATION_KEY = 'utility-navigation';

export class UtilityNavigationComponent extends BaseComponent {
    static getNamespace() {
        return 'utility-navigation';
    }

    static getRequiredRefs() {
        return ['content'];
    }

    onInit() {
        this.fetchData();
    }

    fetchData() {
        baseApi
            .get(CURRENT_USER_NAVIGATION)
            .then((data) => {
                if (data.data) {
                    this.render(data.data);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    render(content) {
        dcFactory.destroy(this.refs.content);

        try {
            this.refs.content.innerHTML = content;
            this.saveContentInLocalStorage(content);
        } catch (err) {
            console.error(err);
        }

        dcFactory.init(this.refs.content);
    }

    saveContentInLocalStorage(content) {
        localStorage.setItem(UTILITY_NAVIGATION_KEY, content);
    }
}

export default UtilityNavigationComponent;
