
import React from 'react';
import { Fragment } from 'preact';
import { Loader } from '@googlemaps/js-api-loader';



export class Map extends React.Component {

    state = {
        zoomLevel: 14
    }
    loader;

    handleMarkerMoved(event, id, lat, lng) {
        this.props.onMarkerMoved(id, lat, lng);
    }

    handleZoomChanged(zoomLevel) {
        this.setState({ zoomLevel: zoomLevel });
    }

    getMapOptions() {
        var mapOptions = {
            center: {
                lat: 0,
                lng: 0
            },
            zoom: this.state.zoomLevel,
            streetViewControl: this.props.streetViewControl,
            mapTypeControl: this.props.mapTypeControl,
            fullscreenControl: this.props.fullscreenControl
        };
        return mapOptions;
    }

    getLocations() {
        if ((this.props.latitude == undefined || this.props.latitude == "")
            || (this.props.longitude == undefined || this.props.longitude == "")) {
            //console.error("Latitude or longitude empty");
            return [];
        }
        else {
            return [{ latitude: this.props.latitude, longitude: this.props.longitude }];
        }
        
    }

    componentDidUpdate() {
        this.drawMap();
    }

    componentDidMount() {

        this.loader = new Loader({
            apiKey: this.props.apiKey,
            version: "weekly",
            libraries: ["places"]
        });


        this.loader.loadCallback(e => {

            if (e) {
                console.log(e);
            } else {
                this.drawMap();
            }
        });

    }

    drawMap() {

        if (this.loader.done) {

            var mapElement = document.getElementById(this.props.id);

            var map = new google.maps.Map(mapElement, this.getMapOptions());
            var mapBounds = new google.maps.LatLngBounds();

            var locations = this.getLocations();

            for (var i = 0; i < locations.length; i++) {

                var location = locations[i];

                var latLong = { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) };

                mapBounds.extend(latLong);

                var marker = new google.maps.Marker({
                    id: location.id,
                    position: latLong,
                    map: map,
                    draggable: true
                });

                google.maps.event.addListener(marker, 'dragend', (event) => {
                    var newLat = marker.getPosition().lat();
                    var newLon = marker.getPosition().lng();
                    this.handleMarkerMoved(event, marker.id, newLat, newLon);
                });

                map.addListener('zoom_changed', (event) => {
                    var zoomLevel = map.getZoom();
                    this.handleZoomChanged(zoomLevel);
                });

           
            }

            //  Set map centre
            if (locations.length == 1) {
                map.panTo(latLong);
            }
            else {
                map.fitBounds(mapBounds);
                map.panToBounds(mapBounds);
            }

        }

    }

    render() {
        var style = "width:" + this.props.width + "px;height:" + this.props.height + "px;";
        return (
            <div style="padding-top:15px;">
                <p><i>You can click and hold the map marker to move it to a more accurate location.</i></p>
                <p><i>If you update this address, the map marker position will be updated.</i></p>
                <div style={style} id={this.props.id}></div>
            </div>

        );
    }

}
