import React from 'react';
import { SubformController } from './subform-controller';
import { Fragment } from 'preact';

export class EntityRow extends SubformController {

    state = {
        subformEditMode: this.props.editMode,
        property: this.props.property,
        isCollapsed: this.props.dataObject.isCollapsed == undefined ? true : this.props.dataObject.isCollapsed,
    }

    initialiseFormFields() {
        var properties = this.props.property.childProperties;
        var dataObject = this.props.dataObject;
        this.formFields = this.props.property.onCreateFormFields(this.props.property.name, properties, dataObject);
    }

    getAccordionTitle = () => {
        return this.props.property.displayName + " " + (parseInt(this.props.dataObject.index) + 1);
    }

    onToggleAccordion = () => {
        if (this.state.isCollapsed) {
            this.state.isCollapsed = false;
        }
        else {
            this.state.isCollapsed = true;
        }
        this.setState({ isCollapsed: this.state.isCollapsed });
    }

    onDelete = () => {
        var property = this.props.property;
        this.props.onDelete(property, this.props.dataObject);
        this.initialiseFormFields();
    }

    render() {

        this.initialiseFormFields();

        var buttonStyle = "margin:10px;";
        var buttonClass = "button button--secondary";
        var editMode = this.props.editMode;
        var subformEditMode = this.state.subformEditMode;
        var fields = this.formFields;

        var inputHelper = this.props.inputHelper;

        var isDeleted = (fields != undefined && fields.deleted != undefined) ? fields.deleted.value : false;
  

        var expanded = this.state.isCollapsed ? false : true;
        var accordionTitle = this.getAccordionTitle();
        var accordionId = this.props.property.name + "-" + this.props.dataObject.index;
        var accordionGroupId = "accordion-group-" + accordionId;
        var accordionButtonId = "accordion-" + accordionId;
        var accordionSectionId = "sect-" + accordionId;


        var hasValidationErrors = inputHelper.hasValidationErrors(fields);
        var accordionClass = hasValidationErrors ? "accordion accordion--washed-chalk accordion--invalid" : "accordion accordion--washed-chalk";
        var accordionClass = isDeleted ? "accordion accordion--washed-chalk accordion--deleted" : accordionClass;
        accordionTitle = isDeleted ? accordionTitle + " (deleted)" : accordionTitle;
        expanded = hasValidationErrors ? true : expanded;
        var hidden = expanded ? "" : "hidden";

        return (

            <dl id={accordionGroupId}
                role="presentation"
                class={accordionClass}
                data-dc-accordion=""
                data-allow-multiple=""
                data-allow-toggle="">

                <dt role="heading" aria-level="3">

                    <button type="button" onClick={this.onToggleAccordion}
                        aria-expanded={expanded} class="accordion__trigger"
                        aria-controls={accordionSectionId}
                        id={accordionButtonId} >

                        <h2 class="accordion__title">{accordionTitle}</h2>
                        <span class="accordion__icon">
                            <span></span><span></span>
                        </span>

                    </button>

                </dt>

                {
                    //  edit view 
                    (fields != undefined) &&
                    <dd id={accordionSectionId}
                        role="region"
                        aria-labelledby={accordionButtonId}
                        class="accordion__panel"
                        hidden={hidden}>

                        {
                            this.props.property.childProperties.map((childProperty) => {

                                var field = inputHelper.getFormFieldByPropertyName(childProperty.name, fields);
                                return (inputHelper.autoGenerateInputField(field, childProperty))

                            })

                            }

                            {
                                (isDeleted != true) &&
                                <div class="entity-row-controls">
                                    <button style={buttonStyle} type="button" class={buttonClass} onClick={this.onDelete}>Delete item</button>
                                </div>
                                
                            }

                            {
                                (isDeleted == true) &&
                                <div class="entity-row-controls">
                                    <button style={buttonStyle} type="button" class={buttonClass} onClick={this.onDelete}>Un-delete item</button>
                                </div>
                                
                            }


                    </dd>
                }

            </dl>


        );
    }


}

