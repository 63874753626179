import { BaseComponent } from 'project/general';

class AudienceSelectorComponent extends BaseComponent {

    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    static getNamespace() {
        return 'audience-selector';
    }

    onInit() {
        this.refs.button.addEventListener('click', this.onClick);
        this.refs.button.addEventListener('keyup', this.keyDown);
    }

    onClick = () => {
        this.refs.list.classList.toggle('active');
    };

    keyDown = () => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            if (this.refs.list.classList.contains('active')) {
                this.refs.list.classList.remove('active');
            } else {
                this.refs.list.classList.add('active');
            }
        }
    };
}

export default AudienceSelectorComponent;
