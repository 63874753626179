import React from 'react';
import { Fragment } from 'preact';

export class OptionPickerWithSearch extends React.Component {

    state = { searchTerm: "", searchResults: [], selectedItems: [] }

    componentDidMount() {

        this.setInitialValues();
    }

    setInitialValues = () => {

        var initialItems = this.props.field.value;
        if (Array.isArray(initialItems) && initialItems.length > 0) {
            for (var i = 0; i < initialItems.length; i++) {
                this.state.selectedItems.push({ id: initialItems[i].id, displayName: initialItems[i].displayName });
            }
            this.setState({ selectedItems: this.state.selectedItems });
        }
        
    }

    onValueChanged = () => {
       // this.props.onValueChanged(this.state.selectedItems);
    }

    onSearchTermChanged = (event) => {

        this.state.searchTerm = event.target.value;

        this.props.searchMethod(this.state.searchTerm).then((searchResults) => {

            if (searchResults != undefined) {

                this.state.searchResults = searchResults;
                this.tickSelectedResults();

                this.setState({ searchResults: this.state.searchResults });
            }
        });

    }

    onSearchResultClick = (event) => {
   
        var itemId = event.target.id;

        var searchResult = this.getItemById(this.state.searchResults, itemId);

        if (searchResult != undefined) {

            if (event.target.checked) {

                searchResult.selected = true;
                this.state.selectedItems.push(structuredClone(searchResult));
                
            }
            else {

                this.removeSelectedItem(itemId);
                searchResult.selected = false;
            }

            this.setState({ selectedItems: this.state.selectedItems });

        }
        
    }

    onRemoveSelectedItemClick = (event) => {

        var itemId = (event.target.id == "") ? event.target.parentElement.id : event.target.id;
        var item = this.getItemById(this.state.selectedItems, itemId);
        if (item != undefined) {
            if (confirm("Remove '" + item.displayName + "' from " + this.props.field.displayName.toLowerCase() + "?") == true) {

                this.removeSelectedItem(itemId);

                this.tickSelectedResults();

                this.setState({ selectedItems: this.state.selectedItems, searchResults: this.state.searchResults });

            }
        }

    }

    onOkClick = (event) => {

        this.setState({ searchResults: [], searchTerm: "" });
        this.onValueChanged();
    }

    removeSelectedItem = (itemId) => {

        if (this.state.selectedItems != undefined
            && this.state.selectedItems.length != undefined)
        {

            for (var i = 0; i < this.state.selectedItems.length; i++) {

                if (this.state.selectedItems[i].id == itemId) {

                    this.state.selectedItems.splice(i, 1);
                    return;

                }

            }

        }

    }

    tickSelectedResults = () => {

        // Handles case where a user
        //  1)  ticks a search result
        //  2)  adjusts the search term such that it removes the ticked search result from the results
        //  3)  adjusts the search term such that it brings the previously ticked
        //      result back to the results view (it won't be ticked any more until we run this logic)

        if (this.state.selectedItems != undefined && this.state.selectedItems.length > 0) {

            for (var i = 0; i < this.state.searchResults.length; i++) {

                var searchResult = this.state.searchResults[i];

                var selectedItem = this.getItemById(this.state.selectedItems, searchResult.id);

                searchResult.selected = (selectedItem == undefined) ? false : true;

            }
        }

        else if (this.state.searchResults != undefined && this.state.searchResults.length > 0) {

            for (var i = 0; i < this.state.searchResults.length; i++) {

                var searchResult = this.state.searchResults[i];
                searchResult.selected = false;

            }

        }

    }

    getItemById = (items, id) => {

        if (items != undefined && items != null && items.length != undefined) {

            for (var i = 0; i < items.length; i++) {

                if (items[i].id == id) {
                    return items[i];
                }

            }

        }
        return undefined;
    }

    render() {

        var hasSearchResults = (this.state.searchResults != undefined && this.state.searchResults.length > 0) ? true : false;
        var hasSelectedItems = (this.state.selectedItems != undefined && this.state.selectedItems.length > 0) ? true : false;

        return (
            <Fragment>
                <div class="option-picker-with-search">

                  
                        <input type="text"
                            onInput={this.onSearchTermChanged}
                            class={this.props.cssClass}
                            placeholder={this.props.searchTermPlaceholder}
                            value={this.state.searchTerm}
                        />
                        {
                            (hasSearchResults) &&
                            <div class="search-results">
                                { 
                                    this.state.searchResults.map((option) => {
                                        return (
                                            <Fragment>
                                                {
                                                    <div class="dropdown">
                                                        <label for={option.id} class="checkbox-label">
                                                            {option.displayName}
                                                            <input type="checkbox"
                                                                id={option.id}
                                                                name="servicepicker"
                                                                value={option.id}
                                                                aria-checked={option.selected}
                                                                onChangeCapture={this.onSearchResultClick}
                                                                aria-label={option.displayName}
                                                                checked={option.selected} />
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                }
                                            </Fragment>
                                        )
                                    })
                                }

                                {
                                    (hasSelectedItems && hasSearchResults) &&
                                    <button type="button" class="button button--primary" onClick={this.onOkClick}>Close list</button>
                                }


                            </div>
                        }

                    <div class="selected-items">

                        {
                            (hasSelectedItems) &&
                            this.state.selectedItems.map((option) => {
                                return (
                                    <div class="dropdown">
                                        <button class="button button--secondary" type="button" onClick={this.onRemoveSelectedItemClick} id={option.id} style="margin-right:10px;">
                                            <svg class=" icon" focusable="false" width="10" height="10" style="margin-top:0px;"><use id={option.id} href="#icon-cross"></use></svg>
                                            <span class="visually-hidden">Remove {option.displayName}</span>
                                        </button>
                                        <label class="">{option.displayName}</label>
                                        <input type="hidden" name={this.props.field.name + "[]"} value={option.id} />
                                    </div>
                                )
                            })
                        }

                    </div>

                </div>
            </Fragment>

        );
    }

}
