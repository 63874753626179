import { BaseComponent } from 'project/general';

class ChatBotComponent extends BaseComponent {

    static getNamespace() {
        return 'chatbot';
    }

    onInit() {
        const chatBot = this.element;
        var moveChatbot = function (event) {
            var footer = document.querySelector('.footer');
            footer.prepend(chatBot);
        };


        // Add our event listeners
        window.addEventListener('load', moveChatbot, false);
    }

}

export default ChatBotComponent;
