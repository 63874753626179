import { BaseComponent } from 'project/general';

class GuidanceSideNavigationComponent extends BaseComponent {

    static getNamespace() {
        return 'guidance-side-navigation';
    }

    onInit() {

        const sideBar = document.querySelector('.guidance__side-navigation-holder');
        const mainContentSection = document.querySelector('.guidance-navigation--subtopic');
        const sideNav = this.element;
        var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        var mobileNavMove = function (event) {
            if (typeof (mainContentSection) != 'undefined' && mainContentSection != null) {
                viewportWidth = window.innerWidth || document.documentElement.clientWidth;
                if (viewportWidth < 964) {
                    sideNav.classList.add('mobile-view');
                    mainContentSection.append(sideNav);
                } else {
                    sideNav.classList.remove('mobile-view');
                    sideBar.prepend(sideNav);
                }
            }
        };

        // Add our event listeners
        window.addEventListener('load', mobileNavMove, false);
        window.addEventListener('resize', mobileNavMove, false);
        
    }

}

export default GuidanceSideNavigationComponent;
