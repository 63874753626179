import React from 'react';
import { EventOptionItemRadio } from './event-option-item-radio';

export class EventOptionListRadio extends React.Component {
    render() {
        const {
            expandedOptions
        } = this.props;
        return (
            <>


                    <div className="form-field form-field--radio">
                        <div className="ktc-radio-list ktc-radio-list--bordered">
                            {expandedOptions.length > 0 && expandedOptions.map((item) => <EventOptionItemRadio name={expandedOptions[0].productIdClientName} {...item} key={item.clientId} />)}
                        </div>
                        <div id="radio-event-error-container"></div>
                    </div>


            </>
        );
    }
}
