import { BaseComponent } from 'project/general';
import { FactoryWatcher } from 'project/factory-watcher';
import { LoqateForm } from 'shared/loqate';

class GenericSectionComponent extends BaseComponent {
    static getNamespace() {
        return 'generic-section';
    }

    onInit() {
        if (this.refs.formBuilder) {
            this.factoryWatcher = new FactoryWatcher(this.element);
            this.loqate = new LoqateForm(this.element);
        }
    }
}

export default GenericSectionComponent;
