import { BaseComponent } from 'project/general';
import { gtmService } from 'project/services';
class VideoComponent extends BaseComponent {

    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    static getNamespace() {
        return 'video';
    }

    onInit() {
        this.refs.play.addEventListener('click', this.onClick);
    }

    onClick = () => {
        var pageName = this.element.getAttribute("data-dc-video-pagename");
        var videoName = this.element.getAttribute("data-dc-video-videoname");
        gtmService.pushEvent('Video Play', "Play", pageName + ' > ' + videoName);
        this.refs.splash.classList.add('video__splash--fade-out');
        this.refs.iframe.src += "&autoplay=1";
    };
}

export default VideoComponent;
