import React from 'react';
import { ArrayHelper } from 'foundation/helpers';
import { AccountAccordion } from 'shared/account-accordion';
import { ParticipantCard } from 'shared/participant-card';

export class AccountEventsList extends React.Component {
    renderParticipantsList(participants) {
        if (ArrayHelper.isEmpty(participants)) return null;

        return (
            <ul className="account-events-accordion__list">
                {
                    participants
                        .map((participant, index) => {
                            return (
                                <li className="account-events-accordion__item" key={index}>
                                    <ParticipantCard participant={participant} bemList={['simple']} />
                                </li>
                            );
                        })
                }
            </ul>
        );
    }

    renderOptions(options) {
        if (ArrayHelper.isEmpty(options)) return null;

        return (
            <div className="account-events-accordion__options">
                {
                    options
                        .map((option, index) => {
                            return (
                                <div className="account-events-accordion__option-item" key={index}>
                                    <span className="account-events-accordion__option-title">{option.title}</span>: {option.value}
                                </div>
                            );
                        })
                }
            </div>
        );
    }

    render() {
        const { products } = this.props;

        if (ArrayHelper.isEmpty(products)) return null;

        return (
            <ul className="account-events-list">
                {
                    products
                        .map((product, index) => {
                            const model = {
                                name: product.eventName,
                                date: product.orderDate,
                                order: product.orderNumber,
                                eventVariants: product.eventVariants,
                                title: 'some Title',
                                accordionId: `accordion-event-${index}`,
                                ariaLabelledby: `accordion-event-label-${index}`,
                                isExpanded: index === 0,
                            };
                            return (
                                <li className="account-orders-list__item" key={model.name}>
                                    <AccountAccordion
                                        id={model.accordionId}
                                        ariaLabelledby={model.ariaLabelledby}
                                        childCss={'account-events-accordion'}
                                        isExpanded={model.isExpanded}
                                        trigger={
                                            <>
                                                <h4 className="account-events-accordion__title heading-4">{model.name}</h4>
                                                <time className="account-events-accordion__date" dateTime={model.date}>{model.date}</time>
                                            </>
                                        }
                                        content={
                                            <>
                                                <div className="account-events-accordion__content">
                                                    <div className="account-events-accordion__order ">Order {model.order}</div>
                                                    <div className="account-events-accordion__details">
                                                        <h4 className="account-events-accordion__title heading-4">{this.props.options.participantListTitle}</h4>
                                                        {this.props.options.participantListNotice &&
                                                            <div className="account__text-partition paragraph-5">
                                                                {this.props.options.participantListNotice}
                                                            </div>
                                                        }
                                                        {model.eventVariants.map((item, i) => (
                                                            <div className="account-events-accordion__variant" key={i}>
                                                                {this.renderOptions(item.eventOptions)}
                                                                {this.renderParticipantsList(item.participants)}
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            </>
                                        }
                                    />
                                </li>
                            );
                        })
                }
            </ul>
        );
    }
}
