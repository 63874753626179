import { BaseComponent } from 'project/general';

class FundraiserTotaliserComponent extends BaseComponent {

    static getNamespace() {
        return 'fundraiser-totaliser';
    }

    static getRequiredRefs() {
        return [
            'targetTotal',
            'currentTotal',
            'currentPercent',
            'currentTotalBlock',
            'animatedBackground',
            'totalAnimationFrames',
            'animationSpeedMilliseconds',
            'animationTriggerElement',
        ];
    }

    onInit() {

        //  Animation variables
        var thisAnimationFrame = 0;
        var totalAnimationFrames = parseInt(this.refs.totalAnimationFrames.value); // link to widget properties?
        var animationSpeedMilliseconds = parseInt(this.refs.animationSpeedMilliseconds.value); // link to widget properties?

        // Get target total value
        let targetTotalNumberValue = this.refs.targetTotal.innerHTML.replace(/,/g, '');
        let targetTotal = parseInt(targetTotalNumberValue);

        // Get current total value
        let currentTotalNumberValue = this.refs.currentTotal.innerHTML.replace(/,/g, '');
        let currentTotal = parseFloat(currentTotalNumberValue).toFixed(2);

        //  Modify target total & current total to fit number of animation frames 
        var targetTotalMod = targetTotal / totalAnimationFrames;
        var currentTotalMod = currentTotal / totalAnimationFrames;

        //  Get variable for each element to be animated
        var backgroundElement = this.refs.animatedBackground;
        var percentRaisedElement = this.refs.currentPercent;
        var totalRaisedElement = this.refs.currentTotalBlock;

        // Get copy Button
        var copyBtn = this.refs.copyBtn;
        var copiedAlert = this.refs.copiedAlert;

        //  Run the animation every x milliseconds until thisAnimationFrame
        //  equals the total animation frames limit
        var myAnimation;

        onVisible(this.refs.animationTriggerElement, () => myAnimation = setInterval(animate, animationSpeedMilliseconds) );

        function onVisible(element, callback) {
            new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        callback(element);
                        observer.disconnect();
                    }
                });
            }).observe(element);
        }

        function animate() {

            var thisAmountMod = (currentTotalMod / totalAnimationFrames) * thisAnimationFrame;
            var currentPercent = Math.floor((thisAmountMod / targetTotalMod) * 100);

            if (thisAmountMod < targetTotalMod) {
                backgroundElement.style.height = currentPercent + "%";
            }
            else {
                backgroundElement.style.height = "100%";
            }

            //  Update current percent
            percentRaisedElement.innerHTML = currentPercent;
            totalRaisedElement.innerHTML = numberWithCommas(parseFloat(currentTotalMod * thisAnimationFrame).toFixed(2));

            if (thisAnimationFrame == totalAnimationFrames) {
                //  Stop the animation
                clearInterval(myAnimation);
            }
            else {
                //  Increment the animation frame
                thisAnimationFrame += 1;
            }

        }

        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }


        // Copy link to clipboard
        copyBtn.addEventListener("click", copyLink);

        function copyLink() {
            var copyLink = copyBtn.dataset.link;
            var textArea = document.createElement("textarea");
            textArea.value = copyLink;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            textArea.remove();


            // On copy show alert
            if (document.execCommand('copy')) {
                copiedAlert.classList.add('fundraiser-totaliser__copied-text--copied');

                var temp = setInterval(function () {
                    copiedAlert.classList.remove('fundraiser-totaliser__copied-text--copied');
                    clearInterval(temp);
                }, 600);

            }
        }




    }

}

export default FundraiserTotaliserComponent;
