import { BaseComponent } from 'project/general';
import { basketController } from 'project/basket';

class MiniBasketComponent extends BaseComponent {
    static getNamespace() {
        return 'mini-basket';
    }

    onInit() {
        //const basket = basketController.getBasket();
        // this.updateMiniBasket(basket); //no need to update on init since minibasket rendered from ajax response

        basketController.subscribeOnUpdate(this.updateMiniBasket);
    }

    updateMiniBasket = (basket) => {
        if (basket.totalQuantity) {
            this.element.classList.remove('is-hidden');
            this.element.setAttribute('aria-hidden', 'false');
        } else {
            this.element.classList.add('is-hidden');
            this.element.setAttribute('aria-hidden', 'true');
        }
    };
}

export default MiniBasketComponent;
