import { BaseComponent } from 'project/general';

class SearchBarComponent extends BaseComponent {

    static getNamespace() {
        return 'search-bar';
    }

    onInit() {
        this.refs.button.addEventListener('focus', this.onFocus);
        this.refs.button.addEventListener('blur', this.onBlur);
    }

    onFocus = () => {
        this.refs.bar.classList.add("focus")
    };

    onBlur = () => {
        this.refs.bar.classList.remove("focus")
    };

}

export default SearchBarComponent;
