import { BaseComponent } from 'project/general';

class FilterSelectComponent extends BaseComponent {

    constructor(...args) {
        super(...args);

        this.onClick = this.onClick.bind(this);
    }

    static getNamespace() {
        return 'filter-select';
    }

    onInit() {
        if (this.refs.button) {
            this.refs.button.addEventListener('click', this.onClick);
        }
    }

    onClick = () => {
        if (this.refs.submenu.classList.contains('active')) {
            this.refs.submenu.classList.remove('active');
        } else {
            this.refs.submenu.classList.add('active');
        }
        if (this.refs.icon.classList.contains('active')) {
            this.refs.icon.classList.remove('active');
        } else {
            this.refs.icon.classList.add('active');
        }
    };

}

export default FilterSelectComponent;

(function ($) {

    var currentUrl = window.location.href;
    var CheckboxDropdown = function (el) {
        var _this = this;
        this.isOpen = false;
        this.areAllChecked = false;
        this.$el = $(el);
        this.$label = this.$el.find('.dropdown-label');
        this.$labelNumber = this.$el.find('.dropdown-number');
        this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
        this.$inputs = this.$el.find('[type="checkbox"]');
        var $clearAllBtn = $('.filter-bar__clear-button').first();

        this.onCheckBox();

        var allDropdowns = $('.dropdown');

        this.$label.on('click', function (e) {
            e.preventDefault();
            allDropdowns.removeClass('on');
            _this.toggleOpen();
        });

        this.$checkAll.on('click', function (e) {
            e.preventDefault();
            _this.onCheckAll();
        });

        $clearAllBtn.on('click', function (e) {
            e.preventDefault();
            _this.clearAllFilters();
        });

        this.$inputs.on('change', function (e) {
            _this.onCheckBox();
        });
    };

    CheckboxDropdown.prototype.onCheckBox = function () {
        this.updateStatus();
    };

    CheckboxDropdown.prototype.updateStatus = function () {
        var checked = this.$el.find(':checked');

        this.areAllChecked = false;
        this.$checkAll.html('Check All');
        var checkedLength = checked.length;

        if (checkedLength <= 0) {
            this.$label.removeClass('active');
            this.$labelNumber.removeClass('active');
            this.$label.html();
            this.$labelNumber.html("");
        }
        else {
            this.$labelNumber.html("");
            this.$label.addClass('active');
            this.$labelNumber.addClass('active');
            this.$labelNumber.html(checkedLength)
        }
    };


    CheckboxDropdown.prototype.toggleOpen = function (forceOpen) {
        var _this = this;

        if (!this.isOpen || forceOpen) {
            this.isOpen = true;

            this.$el.addClass('on');
            $(document).on('click', function (e) {
                if (!$(e.target).closest('[data-control]').length) {
                    _this.toggleOpen();
                }
            });
        }
        else {
            this.isOpen = false;
            this.$el.removeClass('on');
            $(document).off('click');
        }
    };

    CheckboxDropdown.prototype.clearAllFilters = function () {

        var urlClean = currentUrl.split('&');
        this.areAllChecked = false;

        this.$label.removeClass('active');
        this.$labelNumber.removeClass('active');
        this.$label.html();
        this.$labelNumber.html("");
        $(this.$inputs).prop('checked', false);   
        if (window.location.href.indexOf("&") > -1) {
            location.href = urlClean[0];
        }
    };

    var checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
    for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
        new CheckboxDropdown(checkboxesDropdowns[i]);
    }
})(jQuery);
