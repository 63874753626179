import { BaseComponent } from 'project/general';
import { gtmService } from 'project/services';
import { app } from 'general';


class FormWidgetComponent extends BaseComponent {
    static getNamespace() {
        return 'form-widget';
    }

    onInit() {
        this.addListener(this.element, 'submit', this.onSubmit);
    }

    onSubmit = () => {
        this.trackSubmit();
    }

    trackSubmit() {
        gtmService.pushEvent('Kentico Form', app.getConfig('page'), 'Form Submit');
    }
}

export default FormWidgetComponent;
