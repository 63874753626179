import React from 'react';
import { SubformController } from './subform-controller';
import { Fragment } from 'preact';
import { EntityRow } from './entity-row.jsx';

export class EntityRows extends SubformController {

    state = {
        subformEditMode: this.props.editMode,
        property: this.props.property,
    }

    //onDelete = () => {
    //    var property = this.props.property;
    //    this.props.onDelete(property, this.props.dataObject);
    //    this.initialiseFormFields();
    //}

    onAdd = () => {
        var property = this.props.property;
        this.props.onAdd(property);
    }

    render() {


        var rows = this.props.field.value;

        return (
            <p>
                {
                    rows?.map((row) => {
                        return (
                            <EntityRow id={row.id}
                                field={this.props.field}
                                property={this.props.property}
                                dataObject={row}
                                editMode={this.state.subformEditMode}
                                onValueChanged={this.props.onValueChanged}
                                inputHelper={this.props.inputHelper}
                                userEditKey={this.props.userEditKey}
                                onDelete={this.props.onDelete}
                            />
                        )
                    })
                }

                <p><button type="button" class="button button--secondary" onClick={this.onAdd}>New item</button></p>
            </p>
        )
    }


}

