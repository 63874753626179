import { BaseComponent } from 'project/general';
import { AccordionComponent } from 'components/accordion';

export default class ProfileDropdownComponent extends BaseComponent {
    static getNamespace() {
        return 'profile-dropdown';
    }

    static getRequiredRefs() {
        return ['accordion', 'panel', 'trigger'];
    }

    onInit() {
        this.accordionInstance = new AccordionComponent(this.refs.accordion);
        this.accordionInstance.init();

        this.addListener(this.element, 'click', this.onClick);
        this.addListener(document, 'click', this.onDocumentClick);
    }

    onClick = () => {
        if (!this.refs.panel.hasAttribute('hidden')) {
            this.element.classList.add('is-active');
        } else {
            this.element.classList.remove('is-active');
        }
    };

    onDocumentClick = (e) => {
        if (!this.element.contains(e.target)) {
            this.element.classList.remove('is-active');
            this.accordionInstance.collapsePanel(this.refs.trigger);
        }
    };
}
