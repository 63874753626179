import { BaseComponent } from 'project/general';

class SideNavigationComponent extends BaseComponent {

    static getNamespace() {
        return 'side-navigation';
    }

    onInit({ linkClass = "guidance__sidebar-item", hTagsToLink = "h2, h3" } = {}) {

        let jsNav = document.createElement("ul");
        jsNav.classList.add('guidance__sidebar-list');
        let appendArea = document.querySelector('.guidance__sidebar-nav');
        let hTags = document.querySelector('.rich-text-editor').querySelectorAll(hTagsToLink);
        hTags.forEach((el, i) => {
            el.id = `${el.tagName}_${i}`;
            let item = document.createElement("li");
            let link = document.createElement("a");
            link.setAttribute("href", `#${el.tagName}_${i}`);
            item.classList.add(linkClass);
            link.classList.add(`${linkClass}_${el.tagName}`);
            link.textContent = el.textContent;
            item.appendChild(link)
            jsNav.appendChild(item);
        });
        appendArea.appendChild(jsNav);
    }
}

export default SideNavigationComponent;
