import React from 'react';
import { dcFactory } from 'dc';
import { SpriteSvg } from 'shared/sprite-svg';
import { generateBemCss } from 'project/helpers';

export class ParticipantCard extends React.Component {
    constructor(props) {
        super(props);

        this.buttonRef = React.createRef();
    }

    componentDidMount() {
        dcFactory.init(this.buttonRef.current);
    }

    componentWillUnmount() {
        dcFactory.destroy(this.buttonRef.current);
    }

    render() {
        const { participant, bemList } = this.props;

        if (!participant) return null;

        const {
            title,
            subtitle,
            registrationUrl,
            isRegistered
        } = participant;

        let rootCss = generateBemCss('participant-card', bemList);

        if (isRegistered) {
            rootCss = generateBemCss('participant-card', [...bemList, 'done']);
        }

        return (
            <div className={rootCss}>
                <div className="participant-card__col">
                    <h5 className="participant-card__title heading-5">{title}</h5>
                    {subtitle && <span className="participant-card__subtitle paragraph-5">{subtitle}</span>}
                </div>
                <div className="participant-card__col">
                    {
                        !isRegistered &&
                        <>
                            <button
                                className="participant-card__copy copy-to-clipboard"
                                data-dc-copy-to-clipboard={JSON.stringify({ text: registrationUrl })}
                                type="button"
                                ref={this.buttonRef}
                            >
                                <span className="visually-hidden">Copy link</span>
                                <SpriteSvg
                                    childCss={'participant-card__copy-icon'}
                                    iconName={'chain'}
                                    width={15}
                                    height={15}
                                />
                            </button>
                            <a className="participant-card__link" href={registrationUrl}>
                                <span className="visually-hidden">{title}</span>
                                <SpriteSvg
                                    childCss={'participant-card__link-icon'}
                                    iconName={'arrow-right'}
                                    width={15}
                                    height={15}
                                />
                            </a>
                        </>
                    }
                    {
                        isRegistered &&
                        <span className="participant-card__mark" aria-hidden="true">
                            <SpriteSvg
                                childCss={'participant-card__mark-icon'}
                                iconName={'check'}
                                width={10}
                                height={10}
                            />
                        </span>
                    }
                </div>
            </div>
        );
    }
}

ParticipantCard.defaultProps = {
    bemList: [],
};
