import { BaseComponent } from 'project/general';

class PromotionalSliderComponent extends BaseComponent {

    static getNamespace() {
        return 'promotional-slider';
    }

    onInit() {
        var owlPromoCarousel = $(this.element);
        owlPromoCarousel.owlCarousel({
            margin: 10,
            nav: false,
            dots: false,
            autoHeight: true,
            smartSpeed: 1000,
            items: 1,
            loop: false,
            touchDrag: false,
            mouseDrag: false,
            lazyLoad: true,
            onInitialized: function (event) {
                $(owlPromoCarousel).find('.owl-item').attr('aria-selected', 'false');
                $(owlPromoCarousel).find('.owl-item.active').attr('aria-selected', 'true'); // let screen readers know an item is active

                // apply meta info to next and previous buttons and make them focusable
                $(owlPromoCarousel).parent().find('.owl-buttons__button--owl-next').attr('role', 'button').attr('title', 'Previous');
                $(owlPromoCarousel).parent().find('.owl-buttons__button--owl-next').attr('title', 'Next');
                $(owlPromoCarousel).attr('tabindex', '0');
                $('.owl-buttons__button--owl-next, .owl-buttons__button--owl-prev').attr('tabindex', '0');

                // listen for keyboard input
                $(document).on('keydown', function (e) {

                    var $focusedElement = $(document.activeElement),
                        singleOwl = $(owlPromoCarousel).data('owlCarousel'),
                        type = e.which == 39 ? 'next' : null,
                        type = e.which == 37 ? 'prev' : type,
                        type = e.which == 13 ? 'enter' : type;


                    // if the carousel is focused, use left and right arrow keys to navigate
                    if ($focusedElement.attr('id') === "promo-slider") {
                        if (type == 'next') {
                            owlPromoCarousel.trigger('next.owl.carousel');
                        } else if (type == 'prev') {
                            owlPromoCarousel.trigger('prev.owl.carousel');
                        }

                        // if the prev and next buttons are focused, catch "Enter" and navigate in the right direction
                    } else if (type == 'enter') {
                        if ($focusedElement.hasClass('owl-buttons__button--owl-next')) {
                            owlPromoCarousel.trigger('next.owl.carousel');
                        } else if ($focusedElement.hasClass('owl-buttons__button--owl-prev')) {
                            owlPromoCarousel.trigger('prev.owl.carousel');
                        }
                    }
                });
            }
        });

        // Button actions for next & Prev
        $('#promo-next').click(function () {
            owlPromoCarousel.trigger('next.owl.carousel');
        });
        $('#promo-prev').click(function () {
            owlPromoCarousel.trigger('prev.owl.carousel');
        });
    }

}

export default PromotionalSliderComponent;
