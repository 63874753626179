import React from 'react';
import { Fragment } from 'preact';
import ReactModal from 'shared/modal/js/react-modal.jsx';
import { SpriteSvg } from 'shared/sprite-svg';
export class FormFieldLabel extends React.Component {

    state = {
        opentooltip: false
    }

    onOpenTooltip = () => {
        this.state.opentooltip = true;
        this.setState({ opentooltip: this.state.opentooltip });
    }

    onCloseTooltip = () => {
        this.state.opentooltip = false;
        this.setState({ opentooltip: this.state.opentooltip });
    }

    render() {
        var property = this.props.field.property;
        var iconSize = 18;
        var required = (property.required == undefined || property.required == false) ? false : true;
        var labelText = required ? property.displayName + " *" : property.displayName;
        var toolTip = property.tooltip;
        var inputElementId = this.props.field.inputElementId;
        return (
            <div class="label-container">

                <div>
                    <label for={inputElementId} class="form-field__label">{labelText}</label>
                </div>

                {
                    (toolTip != undefined && toolTip != "") &&
                    <div>
                        <button class="tooltip-button"
                            type="button"
                            onClick={this.onOpenTooltip} >
                                <SpriteSvg iconName={'info'} width={iconSize} height={iconSize} />
                        </button>
                    </div>
                }

                {
                    (this.state.opentooltip == true) &&
                    <ReactModal onClose={this.onCloseTooltip} isOpen={this.state.opentooltip}>
                        <div class="box box--base">
                            <label class="form-field__label">{property.displayName}</label>
                            <Fragment>
                                    <p>{toolTip}</p>
                                    {
                                        (required) &&
                                        <p> This is a required field.</p>
                                    }
                            </Fragment>
                        </div>
                    </ReactModal>
                }

            </div>
        );
    }

}
