import React from 'react';
import { Pagination } from 'shared/pagination';
import { AccountOrdersList } from './account-orders-list';

export const AccountOrdersLayout = (props) => {
    const renderCount = (count) => {
        return count !== 1 ?
            `${count} orders` :
            `${count} order`;
    };

    return (
        <>
            <div className="account-orders__wrapper">
                <h2 className="account-orders__title heading-2">My orders</h2>
                {props.count > 0 &&
                    <span className="account-orders__count">{renderCount(props.count)}</span>
                }
            </div>
            {props.count === 0 &&
                <h3 className="account-orders__no-orders heading-3">{props.noOrderMessage}</h3>
            }
            <div className="account-orders__list">
                <AccountOrdersList products={props.products} />
            </div>
            <div className="account-orders__pagination">
                <Pagination
                    pager={props.pager}
                    onSelectPage={props.selectPage}
                />
            </div>
        </>
    );
};
