import React from 'react';
import { Fragment } from 'preact';
export class TextAreaInput extends React.Component {

    onValueChanged = (event) => {
        this.props.field.setValue(event.target.value, this.props.userEditKey);
        this.props.onValueChanged(this.props.field);
    }

    render() {
        return (
            <Fragment>
                {
                    (this.props.editMode == true) &&
                    <textarea
                            id={this.props.field.inputElementId}
                            name={this.props.field.inputElementName}
                            cols={this.props.cols}
                            rows={this.props.rows}
                            value={this.props.field.value}
                            onChangeCapture={this.onValueChanged}
                            class={this.props.cssClass}></textarea>
                }
                {
                    (this.props.editMode == false) &&
                    this.props.field.value
                }
            </Fragment>
        );
    }

}
